import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { CatchErrors } from '../common';

import {
  taskCompletedHourIndexRequest, taskCompletedHourIndexSuccess, taskCompletedHourIndexFailure,
  taskCompletedHourCreateSuccess, taskCompletedHourCreateFailure, taskCompletedHourReadSuccess,
  taskCompletedHourReadFailure, taskCompletedHourUpdateSuccess, taskCompletedHourUpdateFailure,
  taskCompletedHourDeleteSuccess, taskCompletedHourDeleteFailure
} from './actions';
import { taskHistoryIndexRequest } from '../taskHistory/actions';

export function* onIndex({ payload }) {
  try {
    const { id, projectId } = payload;

    const response = yield call(api.get, `projects/${projectId}/tasks/${id}/hours`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCompletedHourIndexSuccess(data));
  }
  catch (err) {
    yield put(taskCompletedHourIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { projectId, taskId, startAt, endAt } = payload.data;

    const formData = new FormData();

    formData.append('startAt', moment(startAt).format("YYYY-MM-DD HH:mm"));
    formData.append('endAt', moment(endAt).format("YYYY-MM-DD HH:mm"));
    formData.append('manual', 1);

    const response = yield call(api.post, `projects/${projectId}/tasks/${taskId}/hours`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCompletedHourCreateSuccess(data));
    yield put(taskCompletedHourIndexRequest(taskId, projectId));
    yield put(taskHistoryIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));

    toast.success('Tempo adicionado com sucesso');
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCompletedHourCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id, projectId, taskId } = payload;

    const response = yield call(api.get, `projects/${projectId}/tasks/${taskId}/hours/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCompletedHourReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCompletedHourReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id, projectId, taskId, startAt, endAt } = payload.data;

    const formData = new FormData();

    if (startAt) formData.append('startAt', moment(startAt).format("YYYY-MM-DD HH:mm"));
    if (endAt) formData.append('endAt', moment(endAt).format("YYYY-MM-DD HH:mm"));
    formData.append('manual', 1);

    const response = yield call(api.post, `projects/${projectId}/tasks/${taskId}/hours/${id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCompletedHourUpdateSuccess(data));
    yield put(taskCompletedHourIndexRequest(taskId, projectId));
    yield put(taskHistoryIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));

    toast.success('Tempo atualizado com sucesso');
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCompletedHourUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id, taskId, projectId } = payload;

    const response = yield call(api.delete, `projects/${projectId}/tasks/${taskId}/hours/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(taskCompletedHourDeleteSuccess());
    yield put(taskCompletedHourIndexRequest(taskId, projectId));
    yield put(taskHistoryIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCompletedHourDeleteFailure());
  }
}

export default all([
  takeLatest('@taskCompletedHours/INDEX_REQUEST', onIndex),
  takeLatest('@taskCompletedHours/CREATE_REQUEST', onCreate),
  takeLatest('@taskCompletedHours/READ_REQUEST', onRead),
  takeLatest('@taskCompletedHours/UPDATE_REQUEST', onUpdate),
  takeLatest('@taskCompletedHours/DELETE_REQUEST', onDelete),
]);
