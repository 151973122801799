import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Spin, Col, Row, Statistic } from 'antd';

import { handleTaskCounter } from '~/pages/Task/Card/functions';

import { dashboardIndexRequest } from '~/store/modules/dashboard/actions';

import { LikeOutlined, BugOutlined, SolutionOutlined, HourglassOutlined } from '@ant-design/icons';

import { Container, NoContent, Content, Card } from './styles';

export default function Home(props) {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(props?.location.search);
  const month = searchParams.get('month');

  const loading = useSelector(store => store.dashboard.loading);
  const result = useSelector(store => store.dashboard.registry);

  useEffect(() => {
    dispatch(dashboardIndexRequest(!month ? undefined : [{ month }]));
  }, []);

  return (
    <Container>
      {loading ? (
        <NoContent>
          <Spin />
        </NoContent>
      ) : (
        <Content>
          <Row style={{ marginBottom: '1rem' }}>
            <Col xs={24}>
              <h1>Dashboard</h1>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={12} sm={8} md={6} xl={4}>
              <Card bordered={true}>
                <Statistic
                  title="Total de tarefas"
                  value={result?.totalTasks}
                  valueStyle={{
                    color: 'var(--card-task-border)'
                  }}
                  prefix={<SolutionOutlined />}
                />
              </Card>
            </Col>

            <Col xs={12} sm={8} md={6} xl={4}>
              <Card bordered={true}>
                <Statistic
                  title="Total bugs"
                  value={result?.totalBugs}
                  valueStyle={{
                    color: '#cf1322',
                  }}
                  prefix={<BugOutlined />}
                />
              </Card>
            </Col>

            <Col xs={12} sm={8} md={6} xl={4}>
              <Card bordered={true}>
                <Statistic
                  title="Cards ativos"
                  value={result?.active}
                  valueStyle={{
                    color: '#3f8600',
                  }}
                  prefix={<LikeOutlined />}
                />
              </Card>
            </Col>

            <Col xs={12} sm={8} md={6} xl={4}>
              <Card bordered={true}>
                <Statistic
                  title="Hrs mês atual"
                  value={handleTaskCounter(result?.totalHoursCurrent, '00h00')}
                  valueStyle={{
                    color: 'var(--project-icon-backlog)'
                  }}
                  prefix={<HourglassOutlined />}
                />
              </Card>
            </Col>
          </Row>
        </Content>
      )}
    </Container>
  );
}
