import produce from 'immer';

const INITIAL_STATE = {
  list: [],
};

export default function mailTerms(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@mailTerms/INSERT_SUCCESS':
      return produce(state, draft => {
        draft.list = action.payload.data;
      });

    case '@mailTerms/CREATE_SUCCESS':
      return produce(state, draft => {
        draft.list = [action.payload.data, ...state.list];
      });

    case '@mailTerms/UPDATE_SUCCESS':
      return produce(state, draft => {
        const whiteList = state.list.filter(item => item.key !== action.payload.data.keyOld);
        draft.list = [action.payload.data, ...whiteList];
      });

    case '@mailTerms/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.list = state.list.filter(item => item.key !== action.payload.key);
      });

    case '@mailTerms/CLEAN_SUCCESS':
      return produce(state, draft => {
        draft.list = [];
      });

    default:
      return state;
  }
}
