import styled, { keyframes } from 'styled-components';
import { lighten, darken } from 'polished';

const pulseTask = keyframes`
  0% {
    border: 0.3rem solid ${darken(0.1, '#fadb14')};
	}
  
  20% {
    border: 0.3rem solid #fadb14;
	}
  
  40% {
    border: 0.3rem solid #${lighten(0.1, '#fadb14')};
  }

  60% {
	  border: 0.3rem solid #${lighten(0.1, '#fadb14')};
	}

  80% {
	  border: 0.3rem solid #fadb14;
	}

	100% {
	  border: 0.3rem solid ${darken(0.1, '#fadb14')};
	}
`;

const pulseBug = keyframes`
  0% {
    border: 0.3rem solid ${darken(0.1, '#ff0014')};
	}
  
  20% {
    border: 0.3rem solid #ff0014;
	}
  
  40% {
    border: 0.3rem solid #${lighten(0.1, '#ff0014')};
  }

  60% {
    border: 0.3rem solid #${lighten(0.1, '#ff0014')};
	}

  80% {
    border: 0.3rem solid #ff0014;
	}

	100% {
    border: 0.3rem solid ${darken(0.1, '#ff0014')};
	}
`;

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  background-color: #dedede;
  position: fixed;
  width: 4rem;
  height: 4rem;
  top: 100%;
  left: 100%;
  margin-top: -5rem;
  margin-left: -5rem;
  margin-bottom: -4rem;
  margin-right: -4rem;
  border-radius: 1rem;
  border: 0.3rem solid #999;
  align-items: center;
  justify-content: center;
	animation: ${props => Number(props.type) === 1 ? pulseTask : pulseBug} 2s infinite;
  z-index: 777;

  svg {
    fill: #28a745;
    font-size: 1.7rem;
  }
`;
