import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { mailSendCreateSuccess, mailSendCreateFailure } from './actions';

export function* onCreate({ payload }) {
  try {
    const values = payload.data;

    const response = yield call(api.post, 'mail/send', values);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    yield put(mailSendCreateSuccess());
    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(mailSendCreateFailure());
  }
}

export default all([
  takeLatest('@mailSend/CREATE_REQUEST', onCreate),
]);
