import styled from 'styled-components';
import { Col, Space, Dropdown as _Dropdown, Menu as _Menu } from 'antd';

export const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  height: 70px;
  margin: 0 auto;

  @media only screen and (max-width: 992px) {
    height: 68px;
  }

  .menu-burger {
    display: none;
    color: var(--input-text);
    font-size: 28px;
    margin: 20px;
 
    @media only screen and (max-width: 992px) {
      display: block;
    }
  }
`;

export const Content = styled(Space)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 0;

  .ant-space-item:first-child {
    width: 100%;
  }
  
  .ant-space-item:last-child {    
    cursor: pointer;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    svg {
      cursor: pointer;
      color: var(--input-text);
      font-size: 1.2rem;
      margin-left: 0.8rem;
    }
  }
`;

export const Dropdown = styled(_Dropdown)`

`;

export const Menu = styled(_Menu)`

`;

export const UserMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 1px 0 0 0;
  border-top-width: 0;
  width: 280px;
  min-width: 160px;
  margin: 1px;
  margin-top: 2px;
  box-shadow: none;
  border-color: var(--border-secondary);
  list-style: none;
  background-color: var(--body-background);

  ul {
    width: 100%;
    background-color: var(--body-background);

    li {
      width: 100%;

      svg {
        margin-right: 10px;
      }

      &:hover {
        span {
          color: var(--button-primary);
        }
      }
    }
  }

  .user-menu-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &:first-child {
      height: 175px;
      text-align: center;
      background-color: var(--header-background);

      p {
        color: var(--input-text);
        font-size: 17px;
        margin: 0;
        margin-top: 10px;
      }
    }

    &:last-child {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .ant-dropdown-menu {
    box-shadow: none;    
  }
`;

export const UserInfo = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
  padding-right: 20px;
`;

export const UserName = styled.span`
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
`;

export const UserPerfil = styled.span`
  color: rgba(255, 255, 255, 0.65);
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const TaskContainer = styled.div`
  min-width: 280px;
  width: 80vw;
  max-width: 340px;
  height: ${props => props.hasData ? '80vh' : 'auto'};
  max-height: 480px;
  overflow: hidden;
  overflow-y: auto;
  border: 0.1rem solid #ccc;
  border-radius: 4px;
  box-shadow: 0 5px 7.8px 0 rgb(0 0 0 / 25%), 0 0.6px 1.8px 0 rgb(0 0 0 / 23%);
`;

export const TaskItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  
  span, small {
    width: 100%;
    text-align: left;
  }
`;
