import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, DatePicker } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { sprintCreateRequest } from '~/store/modules/sprint/actions';

export default function Create({ projectId, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.sprint.saving);
  const error = useSelector(store => store.sprint.error);

  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    const { name, startAt, endAt } = values;

    dispatch(sprintCreateRequest(projectId, {
      name,
      startAt: moment(startAt).format('YYYY-MM-DD'),
      endAt: moment(endAt).format('YYYY-MM-DD'),
    }));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({
      name: '',
      startAt: undefined,
      endAt: undefined
    });
  }

  useMemo(() => {
    if (isSaving && !loading && isVisible) {
      setIsSaving(false);

      if (!error) {
        setIsVisible(false);
        onReset();
      }
    }
  }, [loading]);

  return (
    <Modal
      title="Nova sprint"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={500}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Início"
              name="startAt"
              rules={[{ required: true, message: 'Início é obrigatório' }]}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Término"
              name="endAt"
              rules={[{ required: true, message: 'Término é obrigatório' }]}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
