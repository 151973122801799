import styled from 'styled-components';

import _Modal from '~/components/Modal';

export const Modal = styled(_Modal)`
  .ant-modal-body {
    padding: 0 !important;
    height: 290px;
    background: #000;

    iframe {
      width: 100%;
      height: 280px;
      margin-top: 0.8rem;
      padding: 0;
    }
  }

  .ant-modal-footer {
    display: none;
  }
`;
