import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Space, Dropdown, Menu, Avatar, Alert, Table, Tooltip } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';

import DataList from '~/components/DataList';

import { avatarColors } from '~/helpers/lists';

import { projectReadRequest } from '~/store/modules/project/actions';
import { projectTeamIndexRequest, projectTeamDeleteRequest, projectTeamCreateRequest } from '~/store/modules/projectTeam/actions';
import { userIndexRequest } from '~/store/modules/user/actions';

import { MoreOutlined, UserOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { MenuButton } from '~/pages/_layouts/default/styles';
import { Container, FilterContainer, ProjectInfo, NoContent, Combobox } from './styles';

const { Column } = Table;

export default function ProjectTeam(props) {
  const dispatch = useDispatch();

  const { projectId } = props?.match?.params;

  const dataSource = useSelector(store => store.projectTeam.list);
  const loading = useSelector(store => store.projectTeam.loading);
  const project = useSelector(store => store.project.registry);
  const usersLoading = useSelector(store => store.user.loading);
  const usersData = useSelector(store => store.user.list);

  const [userId, setUserId] = useState(undefined);
  const [users, setUsers] = useState([]);

  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);

  function onAdd() {
    if (!userId) {
      toast.error('Usuário não informado');
      return;
    }

    dispatch(projectTeamCreateRequest({ projectId, userId }));
  }

  function onItemDelete(id) {
    try {
      if (window.confirm("Deseja realmente excluir?")) {
        dispatch(projectTeamDeleteRequest(id, projectId));
      }
    }
    catch (err) {
      //
    }
  }

  useEffect(() => {
    dispatch(userIndexRequest());
    dispatch(projectReadRequest(projectId));
    dispatch(projectTeamIndexRequest(projectId));
  }, [projectId]);

  useMemo(() => {
    const result = [];

    const us = usersData?.map(u => {
      var i = 0;

      var words = u.name.split(' ');
      if (words.length <= 1) words = u.name.split('_');
      if (words.length <= 1) words = u.name.split('/');
      if (words.length <= 1) words = u.name.split('|');
      if (words.length <= 1) words = u.name.split('-');

      const initials = words.map(w => {
        if (i >= 2) return '';
        if (w.trim().length <= 0) return '';

        const letter = w.trim().substring(0, 1);

        if (!letter.match(/[a-z]/i)) return '';

        i++;

        return letter.toUpperCase();
      });

      const avatarColor = avatarColors[moment(u.createdAt).unix() % avatarColors.length];

      result.push({
        id: u.id,
        name: u.name,
        short: initials,
        color: avatarColor,
        icon: undefined
      });
    });

    setUsers(result);
  }, [usersData]);

  return (
    <Container>
      {!project || !project?.id ? (
        <NoContent>
          <Alert
            message={<h1>Projeto não encontrado.</h1>}
            description={(
              <span>Não há como configurar o time para este projeto.</span>
            )}
            type="warning"
            showIcon
          />
        </NoContent>
      ) : (
        <Container>
          <FilterContainer>
            <Row>
              <Col xs={24} md={12} lg={15}>
                <ProjectInfo>
                  {project?.name && <span>{project?.name} Time</span>}
                </ProjectInfo>
              </Col>

              <Col xs={24} md={12} lg={9}>
                <Combobox loading={usersLoading} defaultValue={undefined}
                  onChange={e => setUserId(e)}
                  suffixIcon={
                    <Tooltip title="Incluir no time">
                      <PlusOutlined onClick={() => onAdd()} />
                    </Tooltip>
                  } style={{ width: '100%' }}>
                  {users?.map(u => (
                    <Combobox.Option key={u.id} value={u.id}>
                      <Space>
                        <Avatar size="18" icon={u.icon} style={{ backgroundColor: u.color, verticalAlign: 'middle' }}>
                          {u.short}
                        </Avatar>
                        <span>{u.name}</span>
                      </Space>
                    </Combobox.Option>
                  ))}
                </Combobox>
              </Col>
            </Row>
          </FilterContainer>

          <DataList dataSource={dataSource} loading={loading}>
            <Column key="id" dataIndex="id" width={50}
              render={(text, record) => (
                <Space size="middle" className="action">
                  <Dropdown
                    placement="bottomLeft"
                    arrow
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key="2"
                          onClick={() => onItemDelete(record.id)}
                          icon={<DeleteOutlined />}
                        >Remover</Menu.Item>
                      </Menu>
                    )} >
                    <MenuButton style={{ border: 'none' }}>
                      <MoreOutlined />
                    </MenuButton>
                  </Dropdown>
                </Space>
              )} />
            <Column title="Usuário" align="left" dataIndex="name" key="name" sorter={{
              compare: (a, b) => a.name.localeCompare(b.name)
            }} render={(_, record) => {
              const u = users.find(a => a.id === record.userId);

              if (u) {
                return (
                  <Space>
                    <Avatar icon={u.icon} style={{ backgroundColor: u.color, verticalAlign: 'middle' }}>
                      {u.short}
                    </Avatar>
                    <span>{u.name}</span>
                  </Space>
                )
              }
            }} />
          </DataList>
        </Container>
      )}
    </Container>
  );
}