import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Dropdown, Space, Button, Menu } from 'antd';
import Dropzone from 'react-dropzone';
import filesize from 'filesize';
import { uniqueId } from 'lodash';

import { isNullOrEmpty } from '~/helpers/util';
import { emailType as types } from '~/helpers/lists';

import ModalTermCreate from './Terms/Create';

import { mailSendCreateRequest } from '~/store/modules/mailSend/actions';
import { mailTermsDeleteSuccess, mailTermsCleanSuccess } from '~/store/modules/mailTerms/actions';

import { PlusOutlined, UploadOutlined, DeleteFilled, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Container, DropContainer, DataList, Column, MenuButton } from './styles';


export default function Send() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.mailSend.loading);
  const mailTerms = useSelector(store => store.mailTerms.list);

  const [term, setTerm] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [form] = Form.useForm();

  let myfiles = [];

  function onFinish(values) {
    if (window.confirm('Deseja enviar este e-mail?')) {
      const formData = new FormData();
      formData.encType = 'multipart/form-data';

      const keys = Object.keys(values);

      for (let i = 0; i < keys.length; i++) {
        if (!isNullOrEmpty(values[keys[i]])) {
          formData.append(keys[i], values[keys[i]]);
        }
      };

      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append(`file_${i}`, JSON.stringify(uploadedFiles[i]));
      };

      for (let i = 0; i < mailTerms.length; i++) {
        formData.append(`term_${i}`, JSON.stringify(mailTerms[i]));
      };

      dispatch(mailSendCreateRequest(formData));
      setIsSaving(true);
    }
  }

  function onUpload(files) {
    const upFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      message: '',
      dateUpload: undefined,
      base64: '',
      fileKey: ''
    }));

    myfiles = uploadedFiles.concat(upFiles);

    setUploadedFiles(myfiles);

    myfiles.forEach(processUpload);
  };

  function updateFile(id, data) {
    const result = myfiles.map(file => {
      return id === file.id ? { ...file, ...data } : file;
    });

    setUploadedFiles(result);
    myfiles = result;
  };

  function deleteFile(id) {
    if (window.confirm('Deseja remover esta foto?')) {
      const result = uploadedFiles.filter(file => id !== file.id);

      setUploadedFiles(result);
      myfiles = result;
    }
  };

  function processUpload(uploadedFile) {
    if (!uploadedFile.uploaded) {
      const reader = new FileReader();

      reader.onloadend = () => {
        updateFile(uploadedFile.id, {
          uploaded: true,
          id: uploadedFile.id,
          base64: reader.result
        });
      };

      reader.readAsDataURL(uploadedFile.file);
    }
  }

  function onItemUpdate(data) {
    setTerm(data);
    setIsVisibleCreate(true);
  }

  function onItemDelete(id) {
    try {
      if (window.confirm("Deseja realmente excluir?")) {
        dispatch(mailTermsDeleteSuccess(id));
      }
    }
    catch (err) {
      //
    }
  }

  useMemo(() => {
    if (!loading && isSaving) {
      setIsSaving(false);
    }
  }, [loading]);

  return (
    <Container>
      <h1>Enviar e-mail</h1>

      <Form
        layout="vertical"
        form={form}
        initialValues={{ type: '' }}
        onFinish={onFinish} encType="multipart/form-data"
      >
        <Row gutter={8}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Tipo"
              name="type"
              rules={[{ required: true, message: 'Tipo é obrigatório' }]}
            >
              <Select>
                <Select.Option key="">Selecione uma opção</Select.Option>
                {types.map(t => (
                  <Select.Option key={t.type}>{t.description}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Destinatário(s)"
              name="to"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Cópia para"
              name="cc"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Cópia oculta para"
              name="cco"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Assunto"
              name="subject"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <div className='text-right w-100 m-b-8'>
              <Button icon={<PlusOutlined />} onClick={() => { setTerm(undefined); setIsVisibleCreate(true); }}
                htmlType='button'>Adicionar termo</Button>
            </div>

            <DataList dataSource={mailTerms}>
              <Column key="id" dataIndex="key" width={50}
                render={(_, record) => (
                  <Space size="middle" className="action">
                    <Dropdown
                      placement="bottomLeft"
                      arrow
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="1"
                            onClick={() => onItemUpdate(record)}
                            icon={<EditOutlined />}
                          >Editar</Menu.Item>
                          <Menu.Item
                            key="2"
                            onClick={() => onItemDelete(record.key)}
                            icon={<DeleteOutlined />}
                          >Remover</Menu.Item>
                        </Menu>
                      )} >
                      <MenuButton style={{ border: 'none' }}>
                        <MoreOutlined />
                      </MenuButton>
                    </Dropdown>
                  </Space>
                )} />
              <Column title="Termo" align="left" dataIndex="key" key="key" sorter={{
                compare: (a, b) => a.key.localeCompare(b.key)
              }} defaultSortOrder="ascend" />
              <Column title="Valor" align="left" dataIndex="value" key="value" sorter={{
                compare: (a, b) => a.value.localeCompare(b.value)
              }} />
            </DataList>
          </Col>

          <Col xs={24}>
            <Form.Item name="attachment">
              <Dropzone noClick onDropAccepted={onUpload}>
                {({ getRootProps, open, getInputProps, isDragActive, isDragReject }) => (
                  <DropContainer
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                  >
                    <input {...getInputProps()} />

                    <div className='text-right w-100 m-b-8 m-t-16'>
                      <Button icon={<UploadOutlined />} onClick={() => { open() }} htmlType='button'>Adicionar anexo(s)</Button>
                    </div>
                  </DropContainer>
                )}
              </Dropzone>

              <DataList dataSource={uploadedFiles}>
                <Column key="id" dataIndex="id" width={50}
                  render={(_, record) => (
                    <Space size="middle" className="action">
                      <Button icon={<DeleteFilled />}
                        onClick={() => deleteFile(record.id)}
                        htmlType='button'
                        type='primary'
                        danger
                      />
                    </Space>
                  )} />
                <Column title="Anexo" align="left" dataIndex="name" key="name" sorter={{
                  compare: (a, b) => a.name.localeCompare(b.name)
                }} render={(_, record) => (
                  <Space>
                    {record.name}
                  </Space>
                )} />
              </DataList>
            </Form.Item>
          </Col>

          <Col xs={24} className='text-right'>
            <Button htmlType='submit' type='primary' loading={loading}>Salvar</Button>
          </Col>
        </Row>
      </Form>

      <ModalTermCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} data={term} />
    </Container>
  );
}