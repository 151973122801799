import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import * as dotenv from 'dotenv';

import Feedback from '~/pages/Feedback';

import { avatarColors } from '~/helpers/lists';

import history from '~/services/history';
import { signFailure } from '~/store/modules/auth/actions';
import { taskIndexRequest } from '~/store/modules/task/actions';

import { UserOutlined, BankOutlined, PoweroffOutlined, BellOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Container, Content, Dropdown, Menu, UserInfo, UserPerfil, UserName, UserMenu } from './styles';

dotenv.config();

export default function Header() {
  const dispatch = useDispatch();

  const taskLoading = useSelector(store => store.task.loading);
  const taskData = useSelector(store => store.task.list);
  const profile = useSelector(store => store.auth.profile);

  const shortName = profile?.name?.split(' ')[0].trim();

  const [avatar, setAvatar] = useState({});
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false);

  const taskRef = useRef(null);

  useEffect(() => {
    dispatch(taskIndexRequest());
  }, [])

  useMemo(() => {
    var i = 0;

    var words = profile.name.split(' ');
    if (words.length <= 1) words = profile.name.split('_');
    if (words.length <= 1) words = profile.name.split('/');
    if (words.length <= 1) words = profile.name.split('|');
    if (words.length <= 1) words = profile.name.split('-');

    const initials = words.map(w => {
      if (i >= 2) return '';
      if (w.trim().length <= 0) return '';

      const letter = w.trim().substring(0, 1);

      if (!letter.match(/[a-z]/i)) return '';

      i++;

      return letter.toUpperCase();
    });

    const avatarColor = avatarColors[moment(profile.createdAt).unix() % avatarColors.length];

    setAvatar({ initials, color: avatarColor });
  }, [profile]);

  useMemo(() => {
    if (taskRef && taskRef.current) taskRef.current.scrollTop = 0;
  }, [taskData]);

  return (
    <Container>
      <Row align="center" justify="end">
        <Content>
          <div className='buttons'>
            <Dropdown
              placement="bottomRight"
              overlay={() => (
                <Menu>
                  <Space align='center' style={{ padding: '1rem' }}>
                    Nenhuma notificação encontrada.
                  </Space>
                </Menu>
              )}
            >
              <BellOutlined />
            </Dropdown>

            <Tooltip title="Enviar feedback">
              <InfoCircleOutlined onClick={() => setIsVisibleFeedback(true)} />
            </Tooltip>
          </div>

          <Dropdown
            placement="bottomRight"
            overlay={() => (
              <UserMenu>
                <div className='user-menu-item'>
                  {!avatar || !avatar?.initials || !avatar?.color ? (
                    <Avatar size={90} icon={<UserOutlined />} />
                  ) : (
                    <Avatar style={{ backgroundColor: avatar?.color, verticalAlign: 'middle' }} size={90}>
                      <span style={{ fontSize: 24 }}>{avatar?.initials}</span>
                    </Avatar>
                  )}

                  <p>{profile?.name}</p>

                  <UserPerfil>{profile?.organizationName}</UserPerfil>

                  <UserPerfil><br />Ver {process.env.REACT_APP_VERSION_MAJOR}.{process.env.REACT_APP_VERSION_MINOR}.{process.env.REACT_APP_VERSION_REVISION}</UserPerfil>
                </div>
                <div className='user-menu-item'>
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => { }}
                      icon={<UserOutlined />}
                    >Gerenciar minha conta</Menu.Item>

                    <Menu.Item
                      key="2"
                      onClick={() => { }}
                      icon={<BankOutlined />}
                    >Alterar minha organização</Menu.Item>

                    <Menu.Item
                      key="3"
                      onClick={() => { if (window.confirm('Deseja realmente sair?')) { dispatch(signFailure()); history.push('/'); } }}
                      icon={<PoweroffOutlined />}
                    >Sair</Menu.Item>
                  </Menu>
                </div>
              </UserMenu>
            )}>
            <Content>
              {!avatar || !avatar?.initials || !avatar?.color ? (
                <Avatar size="large" icon={<UserOutlined />} />
              ) : (
                <Avatar size="large" style={{ backgroundColor: avatar?.color, verticalAlign: 'middle' }}>
                  {avatar?.initials}
                </Avatar>
              )}
              <UserInfo xs={0} sm={24}>
                <UserName>{shortName}</UserName>
              </UserInfo>
            </Content>
          </Dropdown>
        </Content>
      </Row>

      <Feedback isVisible={isVisibleFeedback} setIsVisible={setIsVisibleFeedback} />
    </Container>
  );
}
