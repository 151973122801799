import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  registry: {},
  loading: false,
  error: false
};

export default function mailSend(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@mailSend/CREATE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@mailSend/CREATE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@mailSend/CREATE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    default:
      return state;
  }
}
