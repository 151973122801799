import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  registry: {},
  loading: false,
  saving: false,
  error: false
};

export default function sprint(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@sprints/INDEX_REQUEST':
    case '@sprints/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@sprints/CREATE_REQUEST':
    case '@sprints/UPDATE_REQUEST':
    case '@sprints/DELETE_REQUEST':
      return produce(state, draft => {
        draft.saving = true;
        draft.error = false;
      });

    case '@sprints/CREATE_SUCCESS':
    case '@sprints/UPDATE_SUCCESS':
    case '@sprints/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.saving = false;
      });

    case '@sprints/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@sprints/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.registry = action.payload.data;
      });

    case '@sprints/CREATE_FAILURE':
    case '@sprints/UPDATE_FAILURE':
    case '@sprints/DELETE_FAILURE':
      return produce(state, draft => {
        draft.saving = false;
        draft.error = true;
      });

    case '@sprints/INDEX_FAILURE':
    case '@sprints/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.registry = {};
      });

    default:
      return state;
  }
}
