import React from 'react';
import { Row, Col } from 'antd';

import { Card, AppName, Title, ImageContainer } from '~/pages/_layouts/auth/styles';
import { Container } from './styles';

import history from '~/services/history';

import image from '~/assets/images/error.jpg';

export default function Error404() {
  return (
    <Container>
      <Row style={{ height: '100%' }}>
        <Col xs={0} md={12} lg={13} xl={14} style={{ height: '100%', overflow: 'hidden' }}>
          <ImageContainer>
            <img src={image} height="100%" />
          </ImageContainer>
        </Col>

        <Col xs={24} md={12} lg={11} xl={10} style={{ height: '100%', overflow: 'auto' }}>
          <Card>
            <AppName>
              <hr />
              <div style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
                <span>StationUp</span>
                <span>DevOps</span>
              </div>
              <hr />
              <span>Powered by <a href="https://www.webstationdesign.com.br" target="_blank">WebStation</a></span>
            </AppName>

            <Title>Página não encontrada</Title>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
