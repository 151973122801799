import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import {
  sprintIndexRequest, sprintIndexSuccess, sprintIndexFailure, sprintCreateSuccess, sprintCreateFailure,
  sprintReadRequest, sprintReadSuccess, sprintReadFailure, sprintUpdateSuccess, sprintUpdateFailure,
  sprintDeleteSuccess, sprintDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    const { filters, projectId } = payload;

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `projects/${projectId}/sprints${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(sprintIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(sprintIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { projectId } = payload;

    const response = yield call(api.post, `projects/${projectId}/sprints`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(sprintCreateSuccess(data));
    yield put(sprintReadRequest(projectId, data.id));
    yield put(sprintIndexRequest(projectId));

    toast.success(result.message);

    history.push(`/projects/${projectId}/sprints/${data.id}`);
  }
  catch (err) {
    CatchErrors(err);
    yield put(sprintCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id, projectId } = payload;

    const response = yield call(api.get, `projects/${projectId}/sprints/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(sprintReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(sprintReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id, projectId } = payload.data;

    const response = yield call(api.post, `projects/${projectId}/sprints/${id}`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(sprintUpdateSuccess(data));
    yield put(sprintReadRequest(projectId, id));
    yield put(sprintIndexRequest(projectId));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(sprintUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id, projectId } = payload;

    const response = yield call(api.delete, `projects/${projectId}/sprints/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(sprintDeleteSuccess());
    yield put(sprintIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(sprintDeleteFailure());
  }
}

export default all([
  takeLatest('@sprints/INDEX_REQUEST', onIndex),
  takeLatest('@sprints/CREATE_REQUEST', onCreate),
  takeLatest('@sprints/READ_REQUEST', onRead),
  takeLatest('@sprints/UPDATE_REQUEST', onUpdate),
  takeLatest('@sprints/DELETE_REQUEST', onDelete),
]);