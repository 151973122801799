export function reportCompletedWorkRequest(filters) {
  return {
    type: '@reports/COMPLETED_WORK/REQUEST',
    payload: { filters },
  };
}

export function reportCompletedWorkSuccess(data) {
  return {
    type: '@reports/COMPLETED_WORK/SUCCESS',
    payload: { data },
  };
}

export function reportCompletedWorkFailure() {
  return {
    type: '@reports/COMPLETED_WORK/FAILURE',
  };
}