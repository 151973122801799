import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { CatchErrors } from '../common';

import {
  taskIndexRequest, taskIndexSuccess, taskIndexFailure, taskCreateSuccess, taskCreateFailure, taskReadSuccess,
  taskReadFailure, taskUpdateSuccess, taskUpdateFailure, taskDeleteSuccess, taskDeleteFailure
} from './actions';
import { sprintReadRequest } from '../sprint/actions';

export function* onIndex({ payload }) {
  try {
    const response = yield call(api.get, 'tasks');

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const response = yield call(api.post, `projects/${payload.data?.projectId}/tasks`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCreateSuccess(data));
    yield put(taskIndexRequest());
    yield put(sprintReadRequest(payload.data?.projectId, payload.data?.sprintId));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `projects/${payload.data?.projectId}/tasks/${payload.data?.id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const response = yield call(api.post, `projects/${payload.data?.projectId}/tasks/${payload.data?.id}`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskUpdateSuccess(data));
    yield put(taskIndexRequest());
    yield put(sprintReadRequest(payload.data?.projectId, payload.data?.sprintId));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskUpdateFailure());
  }
}

export function* onUpdateSprint({ payload }) {
  try {
    const { projectId, taskId, sprintId, sprintOldId } = payload.data;

    const response = yield call(api.post, `projects/${projectId}/tasks/${taskId}/sprint/${sprintId}`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskUpdateSuccess(data));
    yield put(sprintReadRequest(projectId, sprintOldId));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `tasks/delete/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(taskDeleteSuccess());
    yield put(taskIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskDeleteFailure());
  }
}

export default all([
  takeLatest('@tasks/INDEX_REQUEST', onIndex),
  takeLatest('@tasks/CREATE_REQUEST', onCreate),
  takeLatest('@tasks/READ_REQUEST', onRead),
  takeLatest('@tasks/UPDATE_REQUEST', onUpdate),
  takeLatest('@tasks/UPDATE_SPRINT_REQUEST', onUpdateSprint),
  takeLatest('@tasks/DELETE_REQUEST', onDelete),
]);