import styled from 'styled-components';

export const Container = styled.div`
  .icons-info {
    span {
    margin-right: 1.3rem;

      svg {
        margin-right: -1.2rem;
      }
    }
  }
  .icon-project {
    svg {
      color: var(--project-icon-project);
    }
  }

  .icon-task {
    svg {
      color: var(--project-icon-sprint);
    }
  }

  .icon-bug {
    svg {
      color: var(--project-icon-repository);
    }
  }
`;
