export function sprintIndexRequest(projectId, filters) {
  return {
    type: '@sprints/INDEX_REQUEST',
    payload: { projectId, filters },
  };
}

export function sprintIndexSuccess(data) {
  return {
    type: '@sprints/INDEX_SUCCESS',
    payload: { data },
  };
}

export function sprintIndexFailure() {
  return {
    type: '@sprints/INDEX_FAILURE',
  };
}

export function sprintCreateRequest(projectId, data) {
  return {
    type: '@sprints/CREATE_REQUEST',
    payload: { projectId, data },
  };
}

export function sprintCreateSuccess(data) {
  return {
    type: '@sprints/CREATE_SUCCESS',
    payload: { data },
  };
}

export function sprintCreateFailure() {
  return {
    type: '@sprints/CREATE_FAILURE',
  };
}

export function sprintReadRequest(projectId, id) {
  return {
    type: '@sprints/READ_REQUEST',
    payload: { projectId, id },
  };
}

export function sprintReadSuccess(data) {
  return {
    type: '@sprints/READ_SUCCESS',
    payload: { data },
  };
}

export function sprintReadFailure() {
  return {
    type: '@sprints/READ_FAILURE',
  };
}

export function sprintUpdateRequest(data) {
  return {
    type: '@sprints/UPDATE_REQUEST',
    payload: { data },
  };
}

export function sprintUpdateSuccess(data) {
  return {
    type: '@sprints/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function sprintUpdateFailure() {
  return {
    type: '@sprints/UPDATE_FAILURE',
  };
}

export function sprintDeleteRequest(projectId, id) {
  return {
    type: '@sprints/DELETE_REQUEST',
    payload: { projectId, id },
  };
}

export function sprintDeleteSuccess() {
  return {
    type: '@sprints/DELETE_SUCCESS',
  };
}

export function sprintDeleteFailure() {
  return {
    type: '@sprints/DELETE_FAILURE',
  };
}