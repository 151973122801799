import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import Error404 from '~/pages/_error404';
import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import Recovery from '~/pages/Recovery';

import Dashboard from '~/pages/Home';
import Project from '~/pages/Project';
import ProjectDetails from '~/pages/Project/Details';
import ProjectTeam from '~/pages/ProjectTeam';
import Schedule from '~/pages/Schedule';
import Sprint from '~/pages/Sprint';
import ReportCompletedWork from '~/pages/Report/CompletedWork';
import MailSend from '~/pages/Mail/Send';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={SignIn} />
      <Route path="/recovery" component={Recovery} />
      <Route path="/register" component={SignUp} />
      <Route path="/404" exact component={Error404} />

      <Route path="/" exact component={Dashboard} isPrivate />
      <Route path="/projects" exact component={Project} isPrivate />
      <Route path="/projects/:id" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:id/backlog" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:id/features" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:id/epics" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:projectId/teams" exact component={ProjectTeam} isPrivate />
      <Route path="/projects/:projectId/sprints/:id" exact component={Sprint} isPrivate />
      <Route path="/projects/:projectId/sprints/:id/tasks/:idTask" exact component={Sprint} isPrivate />
      <Route path="/projects/:id/repositories" exact component={ProjectDetails} isPrivate />
      <Route path="/schedule" exact component={Schedule} isPrivate />
      <Route path="/mails" exact component={MailSend} isPrivate />

      <Route path="/report/completedwork" exact component={ReportCompletedWork} isPrivate />

      <Redirect to="/404" />
    </Switch>
  );
}