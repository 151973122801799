import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';

import Modal from '~/components/Modal';

import { mailTermsCreateSuccess, mailTermsUpdateSuccess } from '~/store/modules/mailTerms/actions';

import { Container } from './styles';

export default function Create({ data, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  function onFinish(values) {
    if (!data) dispatch(mailTermsCreateSuccess(values));
    else dispatch(mailTermsUpdateSuccess(values));

    setIsVisible(false);
    onReset();
  }

  function onReset() {
    form.setFieldsValue({
      keyOld: '',
      key: '',
      value: ''
    });
  }

  useMemo(() => {
    if (!data) {
      onReset();
    }
    else {
      form.setFieldsValue({
        keyOld: data.key,
        key: data.key,
        value: data.value
      });
    }
  }, [data]);

  return (
    <Modal
      title={`${!data ? 'Adicionar' : 'Atualizar'} termo`}
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}>
          <Form.Item name="keyOld" noStyle>
            <Input type='hidden' />
          </Form.Item>

          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item
                label="Termo"
                name="key"
                rules={[{ required: true, message: 'Termo é obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Valor"
                name="value"
                rules={[{ required: true, message: 'Valor é obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal >
  );
}
