import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Table, Avatar, Pagination, Tooltip } from 'antd';
import moment from 'moment';

import { isNullOrEmpty } from '~/helpers/util';

import { taskHistoryIndexRequest } from '~/store/modules/taskHistory/actions';

import { Container, DataList } from './styles';

export default function TaskHistory({ projectId, taskId, users }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.taskHistory.loading);
  const dataSource = useSelector(store => store.taskHistory.list);
  const count = useSelector(store => store.taskHistory.count);
  const pageIndex = useSelector(store => store.taskHistory.page);

  const [pagination, setPagination] = useState({ page: 1, size: 10, count: 0 });

  function onPagination(pageNumber, pageSize) {
    setPagination(prevPagination => ({
      ...prevPagination,
      page: pageNumber,
      size: pageSize
    }));
  }

  useMemo(() => {
    setPagination(prevPagination => ({
      ...prevPagination,
      count: count,
      page: !pageIndex ? 1 : pageIndex
    }));
  }, [dataSource]);

  useMemo(() => {
    let filters = [{ page: pagination.page }, { pageSize: pagination.size }];

    if (!isNullOrEmpty(taskId) && !isNullOrEmpty(projectId)) {
      dispatch(taskHistoryIndexRequest(projectId, taskId, filters));
    }
  }, [pagination.page, pagination.size, taskId]);

  return (
    <Container>
      <DataList dataSource={dataSource} loading={loading} pagination={false}>
        <DataList.Column align="left" dataIndex="id" key="id" render={(_, record) => {
          let commentUser = users.find(u => u.id === record.userId);

          if (!commentUser) commentUser = users.find(u => u.id === undefined);

          return (
            <Row>
              <Col xs={3} md={2}>
                <Tooltip title={commentUser.name}>
                  <Avatar size="18" icon={commentUser.icon} style={{ backgroundColor: commentUser.color, verticalAlign: 'middle' }}>
                    {commentUser.short}
                  </Avatar>
                </Tooltip>
              </Col>
              <Col xs={15} md={18}>
                <span dangerouslySetInnerHTML={{ __html: record.comment.replace(/(?:\r\n|\r|\n)/g, '<br/>') }}></span>
              </Col>
              <Col xs={6} md={4} style={{ textAlign: 'right' }}>
                {moment(record.createdAt).format('DD/MM/YYYY HH:mm')}
              </Col>
            </Row>
          );
        }} />
      </DataList>

      {count > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination defaultCurrent={pagination.page} total={pagination.count} onChange={onPagination} />
        </div>
      )}
    </Container>
  );
}
