import styled from 'styled-components';
import _DataList from '~/components/DataList';

export const Container = styled.div`
  
`;

export const DataList = styled(_DataList)`
  margin-bottom: 2rem;
`;
