import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Skeleton, Input } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { projectUpdateRequest } from '~/store/modules/project/actions';

import { Container } from './styles';

export default function Update({ id, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const saving = useSelector(store => store.project.saving);
  const error = useSelector(store => store.project.error);
  const registry = useSelector(store => store.project.registry);

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  function onFinish(values) {
    values = { ...values, id: id };

    setIsSaving(true);
    dispatch(projectUpdateRequest(values));
  }

  useMemo(() => {
    setLoading(true);
  }, [id]);

  useMemo(() => {
    setLoading(true);

    if (registry?.id) {
      form.setFieldsValue({
        name: registry.name,
      });

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);
      if (!error) setIsVisible(false);
    }
  }, [saving]);

  return (
    <Modal
      title="Editar Projeto"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={isSaving}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item
                label="Título"
                name="name"
                rules={[{ required: true, message: "Título é obrigatório" }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <Input max={254} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}
