import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import ProjectCreate from '~/pages/Project/Create';

import history from '~/services/history';

import { menuProjectIndexRequest } from '~/store/modules/menuProject/actions';

import { ProjectOutlined, ScheduleOutlined, MailOutlined, PlusOutlined, HistoryOutlined, SolutionOutlined } from '@ant-design/icons';
import { Container, Item, ProjectBadge } from './styles';

export default function Menu({ path }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const projects = useSelector(store => store.menuProject.list);
  const projectLoading = useSelector(store => store.menuProject.loading);

  const [selectedMenu, setSelectedMenu] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);

  const menuGroups = [
    // { key: 4, level: 5, title: 'Tarefas' },
    { key: 1, level: 5, title: 'Projetos' },
    { key: 2, level: 7, title: 'Equipe' },
    { key: 3, level: 5, title: 'Administração' },
  ];

  const menuItens = [
    { key: 100, level: 7, group: 2, title: 'Horas trabalhadas', breadcrumb: undefined, icon: <HistoryOutlined />, url: '/report/completedwork' },
    { key: 101, level: 5, group: 3, title: 'Agenda', breadcrumb: undefined, icon: <ScheduleOutlined />, url: '/schedule' },
    { key: 102, level: 9, group: 3, title: 'E-mails', breadcrumb: undefined, icon: <MailOutlined />, url: '/mails' },
    //   { key: 103, level: 5, group: 4, title: 'Minhas tarefas', breadcrumb: undefined, icon: <SolutionOutlined />, url: '/tasks' },
  ];

  useEffect(() => {
    const menu = menuItens.find(a => a.url === path);
    if (menu) setSelectedMenu(menu.key);

    dispatch(menuProjectIndexRequest());
  }, [path]);

  return (
    <Container
      theme="dark"
      defaultSelectedKeys={selectedMenu}
      mode="inline"
    >
      {menuGroups.map(g => {
        if (g && Number(g.level) <= Number(profile.level)) {
          return (
            <>
              <Item key={g.key} group className='menu-group'>
                {g.title}
              </Item>

              {(g.key === 1 && projectLoading) && (
                <Item key="projectLoading" group className='menu-group'>
                  <Spin />
                </Item>
              )}

              {(g.key === 1 && !projectLoading) && (
                <>
                  {projects.map(p => {
                    return (
                      <Item key={p.id} onClick={() => history.push(`/projects/${p.id}/sprints/current`)} icon={<ProjectBadge style={{ backgroundColor: p.color }} />}>
                        {p.name}
                      </Item>
                    )
                  })}
                </>
              )}

              {g.key === 1 && (
                <>
                  <Item key="projectall" icon={<ProjectOutlined />} onClick={() => history.push('/projects')}>
                    Todos os projetos
                  </Item>

                  <Item key="projectcreate" icon={<PlusOutlined />} onClick={() => setIsVisibleCreate(true)}>
                    Novo projeto
                  </Item>
                </>
              )}

              {menuItens.filter(a => a.group === g.key).map(m => {
                if (m && Number(m.level) <= Number(profile.level)) {
                  return (
                    <Item key={m.key} icon={m.icon} onClick={() => history.push(m.url)}>
                      {m.title}
                    </Item>
                  )
                }
              })}
            </>
          )
        }
      })}

      <ProjectCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} />
    </Container>
  );
}
