export function scheduleIndexFirstRequest() {
  return {
    type: '@schedule/INDEX_REQUEST',
    payload: { filters: [{ filterDeleted: 0 }] },
  };
}

export function scheduleIndexRequest(filters) {
  return {
    type: '@schedule/INDEX_REQUEST',
    payload: { filters },
  };
}

export function scheduleIndexSuccess(data) {
  return {
    type: '@schedule/INDEX_SUCCESS',
    payload: { data },
  };
}

export function scheduleIndexFailure() {
  return {
    type: '@schedule/INDEX_FAILURE',
  };
}

export function scheduleCreateRequest(data) {
  return {
    type: '@schedule/CREATE_REQUEST',
    payload: { data },
  };
}

export function scheduleCreateSuccess(data) {
  return {
    type: '@schedule/CREATE_SUCCESS',
    payload: { data },
  };
}

export function scheduleCreateFailure() {
  return {
    type: '@schedule/CREATE_FAILURE',
  };
}

export function scheduleReadRequest(id) {
  return {
    type: '@schedule/READ_REQUEST',
    payload: { id },
  };
}

export function scheduleReadSuccess(data) {
  return {
    type: '@schedule/READ_SUCCESS',
    payload: { data },
  };
}

export function scheduleReadFailure() {
  return {
    type: '@schedule/READ_FAILURE',
  };
}

export function scheduleUpdateRequest(data) {
  return {
    type: '@schedule/UPDATE_REQUEST',
    payload: { data },
  };
}

export function scheduleUpdateSuccess(data) {
  return {
    type: '@schedule/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function scheduleUpdateFailure() {
  return {
    type: '@schedule/UPDATE_FAILURE',
  };
}

export function scheduleDeleteRequest(id, date) {
  return {
    type: '@schedule/DELETE_REQUEST',
    payload: { id, date },
  };
}

export function scheduleDeleteSuccess() {
  return {
    type: '@schedule/DELETE_SUCCESS',
  };
}

export function scheduleDeleteFailure() {
  return {
    type: '@schedule/DELETE_FAILURE',
  };
}
