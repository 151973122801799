import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, DatePicker, Skeleton, Switch } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { isNullOrEmpty } from '~/helpers/util';

import { sprintUpdateRequest } from '~/store/modules/sprint/actions';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export default function Update({ id, projectId, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const registry = useSelector(store => store.sprint.registry);
  const saving = useSelector(store => store.sprint.saving);
  const error = useSelector(store => store.sprint.error);

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filed, setFiled] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    const { name, startAt, endAt } = values;

    dispatch(sprintUpdateRequest({
      id,
      projectId,
      name,
      startAt: moment(startAt).format('YYYY-MM-DD'),
      endAt: moment(endAt).format('YYYY-MM-DD'),
      filed: filed
    }));

    setIsSaving(true);
  }

  useMemo(() => {
    setLoading(true);

    if (!isNullOrEmpty(registry?.id)) {
      form.setFieldsValue({
        name: registry.name,
        startAt: moment(registry.startAt),
        endAt: !registry.endAt ? undefined : moment(registry.endAt)
      });

      setFiled(Number(registry.filed) === 1);

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);

      if (!error) setIsVisible(false);
    }
  }, [saving]);

  return (
    <Modal
      title="Editar sprint"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={500}
      processing={saving}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Nome é obrigatório' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Início"
              name="startAt"
              rules={[{ required: true, message: 'Início é obrigatório' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" />
              ) : (
                <DatePicker format="DD/MM/YYYY" placeholder="" />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Término"
              name="endAt"
              rules={[{ required: true, message: 'Término é obrigatório' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" />
              ) : (
                <DatePicker format="DD/MM/YYYY" placeholder="" />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            {loading ? (
              <Skeleton.Button active size="small" />
            ) : (
              <>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={filed}
                  onChange={(checked) => setFiled(checked)}
                /> Arquivada
              </>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
