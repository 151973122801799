import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  registry: {},
  loading: false,
  saving: false,
  error: false,
  playing: undefined,
  timecount: undefined
};

export default function taskHour(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@taskHours/INDEX_REQUEST':
    case '@taskHours/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@taskHours/CREATE_REQUEST':
    case '@taskHours/UPDATE_REQUEST':
      return produce(state, draft => {
        draft.saving = true;
        draft.error = false;
      });

    case '@taskHours/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@taskHours/CREATE_SUCCESS':
    case '@taskHours/UPDATE_SUCCESS':
    case '@taskHours/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.saving = false;

        if (Number(action.payload.data?.Task?.playing) === 1) {
          action.payload.data.Task.timeId = action.payload.data.id;
          draft.playing = action.payload.data.Task;
        }
        else {
          draft.playing = undefined;
        }
      });

    case '@taskHours/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@taskHours/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.registry = action.payload.data;
      });

    case '@taskHours/INDEX_FAILURE':
    case '@taskHours/CREATE_FAILURE':
    case '@taskHours/UPDATE_FAILURE':
    case '@taskHours/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.saving = false;
      });

    case '@taskHours/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.registry = {};
      });

    case '@taskHours/TIMECOUNT':
      return produce(state, draft => {
        draft.timecount = action.payload.data;
      });

    default:
      return state;
  }
}
