import styled from 'styled-components';
import { darken } from 'polished';
import { Row, Select, Input } from 'antd';

export const Container = styled.div`
  min-width: 100%;
  text-align: center;
  padding: 0.3rem 0;
`;

export const InputSelect = styled(Select)`
  border: 1px solid var(--card-task-input-border);
  background-color: var(--body-background);
  color: var(--card-task-input-text);

  &:hover, &:focus, &:active {
    border: 1px solid var(--card-task-border);
    background-color: var(--card-task-input-background);
  }
`

export const NoContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 5rem;
`;

export const Content = styled.div`
  min-width: 100%;
  overflow-x: hidden;
`;

export const Grid = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 1440px;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: calc(20% - 0.6rem);
  min-width: 200px;
  min-height: 75vh;
  padding: 0.1rem;
  background-color: var(--body-background);

  .card-header, .card-container {
    cursor: default;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .card-header {
    font-weight: 700;
    min-height: 20px;
    border-bottom: 1px solid var(--border-secondary);
    padding: 1rem 0;
    background-color: ${darken(0.05, '#F5F5F5')};
    align-items: center;
    justify-content: center;

    span {
      padding: 0 0.2rem;

      &:last-child {
        display: flex;
        flex-direction: row;
        font-size: 0.7rem;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .card-container {
    flex: 1;
    padding: 0.6rem;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;

  label {
    font-size: 0.8rem;
    text-align: left;
    font-weight: 700;
  }

  label, .ant-select, .ant-input, .ant-btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  button {
    &.ant-btn {
      font-size: 0.8rem;
      width: 100%;
    }
  }

  .ant-row {
    align-items: flex-end;
  }

  .filter-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .sprint-list {
    width: 100%;
  }

  .ant-select-item-option-content {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .select-badge {
    border-radius: 10%;
    padding: 0.5rem 1rem;
  }

  .color-antigo {
    background-color: #f5f5f5;
  }

  .color-atual {
    background-color: #09f;
  }

  .color-futuro {
    background-color: #0f9;
  }
`;

export const ProjectInfo = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--primary-text);
  text-align: left;
`;

export const ItemGroup = styled(Input.Group)`
  display: flex !important;
  align-items: center;

  .ant-select {
    width: calc(100% - 3.4rem);

    & ~ span svg {
      color: var(--project-icon);
      cursor: pointer;
      font-size: 1.3rem;
      margin-left: 0.4rem;
      margin-bottom: 0.5rem;

      &:hover {
        color: var(--project-icon-hover);
      }
    }

    span + small {
      margin-left: 0.4rem;
    }
  }
`;
