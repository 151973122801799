import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import {
  taskCommentIndexRequest, taskCommentIndexSuccess, taskCommentIndexFailure, taskCommentCreateSuccess,
  taskCommentCreateFailure, taskCommentReadSuccess, taskCommentReadFailure, taskCommentUpdateSuccess,
  taskCommentUpdateFailure, taskCommentDeleteSuccess, taskCommentDeleteFailure
} from './actions';
import { taskHistoryIndexRequest } from '../taskHistory/actions';

export function* onIndex({ payload }) {
  try {
    let { projectId, taskId, filters } = payload;
    if (!filters) filters = [];

    let params = '';
    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `projects/${projectId}/tasks/${taskId}/comments`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCommentIndexSuccess(data));
  }
  catch (err) {
    yield put(taskCommentIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { projectId, taskId } = payload.data;

    const response = yield call(api.post, `projects/${projectId}/tasks/${taskId}/comments`, payload.data
    );

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCommentCreateSuccess(data));
    yield put(taskHistoryIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCommentCreateFailure());
  }
  finally {
    yield put(taskCommentIndexRequest(payload.data?.projectId, payload.data?.taskId, [{ page: 1 }, { pageSize: 10 }]));
  }
}

export function* onRead({ payload }) {
  try {
    const { id, projectId } = payload.data;

    const response = yield call(api.get, `projects/${projectId}/taskComments/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCommentReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCommentReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { projectId, taskId } = payload.data;

    const response = yield call(
      api.post,
      `projects/${payload.data?.projectId}/tasks/${payload.data?.taskId}/hours/${payload.data?.id}`,
      payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskCommentUpdateSuccess(data));
    yield put(taskHistoryIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCommentUpdateFailure());
  }
  finally {
    yield put(taskCommentIndexRequest(payload.data?.projectId, undefined, [{ page: 1 }, { pageSize: 10 }]));
  }
}

export function* onDelete({ payload }) {
  try {
    const { projectId, taskId, id } = payload;

    const response = yield call(api.delete, `projects/${projectId}/tasks/${taskId}/comments/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(taskCommentDeleteSuccess());
    yield put(taskCommentIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));
    yield put(taskHistoryIndexRequest(projectId, taskId, [{ page: 1 }, { pageSize: 10 }]));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskCommentDeleteFailure());
  }
}

export default all([
  takeLatest('@taskComments/INDEX_REQUEST', onIndex),
  takeLatest('@taskComments/CREATE_REQUEST', onCreate),
  takeLatest('@taskComments/READ_REQUEST', onRead),
  takeLatest('@taskComments/UPDATE_REQUEST', onUpdate),
  takeLatest('@taskComments/DELETE_REQUEST', onDelete),
]);