import { takeLatest, put, all } from 'redux-saga/effects';

import { mailTermsInsertSuccess } from './actions';

export function* onRefresh({ payload }) {
  if (!payload) return;

  const { list } = payload.mailTerms;

  if (!list || list.length <= 0) return;

  yield put(mailTermsInsertSuccess(list));
}

export default all([
  takeLatest('persist/REHYDRATE', onRefresh),
]);