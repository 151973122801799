import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  registry: {},
  loading: false,
  saving: false,
  error: false
};

export default function taskCompletedHour(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@taskCompletedHours/INDEX_REQUEST':
    case '@taskCompletedHours/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@taskCompletedHours/CREATE_REQUEST':
    case '@taskCompletedHours/UPDATE_REQUEST':
      return produce(state, draft => {
        draft.saving = true;
        draft.error = false;
      });

    case '@taskCompletedHours/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@taskCompletedHours/CREATE_SUCCESS':
    case '@taskCompletedHours/UPDATE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.saving = false;
        draft.registry = action.payload.data;
      });

    case '@taskCompletedHours/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.saving = false;
      });

    case '@taskCompletedHours/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@taskCompletedHours/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.registry = action.payload.data;
      });

    case '@taskCompletedHours/INDEX_FAILURE':
    case '@taskCompletedHours/CREATE_FAILURE':
    case '@taskCompletedHours/UPDATE_FAILURE':
    case '@taskCompletedHours/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.saving = false;
      });

    case '@taskCompletedHours/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.registry = {};
      });

    default:
      return state;
  }
}
