export function dashboardIndexRequest(filters = null) {
  return {
    type: '@dashboard/INDEX_REQUEST',
    payload: { filters },
  };
}

export function dashboardIndexSuccess(data) {
  return {
    type: '@dashboard/INDEX_SUCCESS',
    payload: { data },
  };
}

export function dashboardIndexFailure() {
  return {
    type: '@dashboard/INDEX_FAILURE',
  };
}
