import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import { menuProjectIndexSuccess, menuProjectIndexFailure } from './actions';

export function* onIndex({ payload }) {
  try {
    let { filters } = payload;
    if (!filters) filters = [];

    filters.push({ take: 6 });
    filters.push({ orderByDescUpdatedAt: 1 });

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `projects${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(menuProjectIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(menuProjectIndexFailure());
  }
}

export default all([
  takeLatest('@menu/projects/INDEX_REQUEST', onIndex),
]);