import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import ModalCreate from './Create';
import ModalUpdate from './Update';

import history from '~/services/history';

import { scheduleIndexRequest } from '~/store/modules/schedule/actions';

import { ArrowLeftOutlined, ArrowRightOutlined, SearchOutlined, SettingFilled, CheckCircleFilled, HourglassOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Container, Task } from './styles';

export default function Schedule() {
  const dispatch = useDispatch();

  const dataSource = useSelector(store => store.schedule.list);
  const loading = useSelector(store => store.schedule.loading);

  const [lines, setLines] = useState([]);
  const [day, setDay] = useState();
  const [date, setDate] = useState();
  const [hour, setHour] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [id, setId] = useState();
  const [width, setWidth] = useState(0);
  const [lineHour, setLineHour] = useState(false);
  const [lineHourPos, setLineHourPos] = useState(0);
  const [timeoutcount, setTimeoutcount] = useState(0);

  const tableRef = useRef(null);
  const lineRef = useRef(null);

  const lineHeight = 22;

  function updateWidth() {
    if (lineRef.current) {
      setWidth(lineRef.current.offsetWidth);
    }
  };

  function createTask(hr) {
    setHour(hr);
    setIsVisibleCreate(true);
  }

  function updateTask(item) {
    const { id } = item;

    if (Number(item.type) === 0) {
      setId(id);
      setIsVisibleUpdate(true);
      setTimeout(() => {
        setIsVisibleCreate(false);
      }, 100);
    }
    else {
      history.push(`projects/${item.projectId}/sprints/${item.sprintId}/tasks/${item.id}`);
    }
  }

  function scrollToHour(hour) {
    const row = document.getElementById(`schedule_${lines.findIndex(line => line === hour)}`);
    if (row && tableRef.current) {
      const offsetTop = row.offsetTop;
      tableRef.current.scrollTop = offsetTop - (lineHeight * 4);

      const min = (Number(moment().format('mm')) / 15) * 22;

      setLineHourPos(offsetTop + lineHeight + min - 4);
    }
  }

  useEffect(() => {
    let grid = [];

    let hr = 0;
    let min = 0;

    for (let i = 0; i < 96; i++) {
      if (i % 4 === 0) {
        hr = i / 4;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:00`);
      }
      else {
        min = (i % 4) * 15;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:${min}`);
      }
    }
    setLines(grid);

    setDate(moment().format('YYYY-MM-DD'));
    setDay(moment().format("dddd, DD [de] MMMM [de] YYYY"));
  }, []);

  useEffect(() => {
    dispatch(scheduleIndexRequest([{ date: moment(date).format('YYYY-MM-DD') }]));
    setDay(moment(date).format("dddd, DD [de] MMMM [de] YYYY"));

    const isDay = moment(date).format("dddd, DD [de] MMMM [de] YYYY") === moment().format("dddd, DD [de] MMMM [de] YYYY");
    setLineHour(isDay);

    if (isDay) {
      const scHr = `${moment().format('HH')}:00`;

      scrollToHour(scHr);
    }
  }, [date]);

  useEffect(() => {
    setTimeout(() => {

      updateWidth();

      window.addEventListener('resize', updateWidth);

      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    }, 1000);
  }, [lineRef]);

  useMemo(() => {
    setTimeout(() => {
      setTimeoutcount(timeoutcount + 1);
    }, 30000);
  }, [dataSource]);

  useMemo(() => {
    const isDay = moment(date).format("dddd, DD [de] MMMM [de] YYYY") === moment().format("dddd, DD [de] MMMM [de] YYYY");

    if (isDay) {
      const scHr = `${moment().format('HH')}:00`;
      scrollToHour(scHr);
    }

    dispatch(scheduleIndexRequest([{ date: moment(date).format('YYYY-MM-DD') }]));
  }, [timeoutcount]);

  return (
    <Container>
      <Row>
        <Col xs={24} className='header'>
          <Button type='ghost' htmlType='button' onClick={() => setDate(moment().format('YYYY-MM-DD'))}>Hoje</Button>

          <div className='today'>
            <Button type='text' htmlType='button' icon={<ArrowLeftOutlined />} onClick={() => setDate(moment(date).add(-1, "d").format('YYYY-MM-DD'))}></Button>
            <span>{day}</span>
            <Button type='text' htmlType='button' icon={<ArrowRightOutlined />} onClick={() => setDate(moment(date).add(1, "d").format('YYYY-MM-DD'))}></Button>
          </div>

          <div>
            <Button type='text' htmlType='button' icon={<SearchOutlined />}></Button>
            <Button type='text' htmlType='button' icon={<SettingFilled />}></Button>
          </div>
        </Col>

        <Col xs={24} className='grid' ref={tableRef}>
          <table>
            <tbody>
              <tr>
                <td style={{ height: 1 }}></td>
                <td style={{ height: 1 }} ref={lineRef}></td>
              </tr>
              {lines.map((hr, i) => (
                <tr>
                  <td className={hr.split(':')[1] === '00' && 'separator'}>
                    {hr.split(':')[1] === '00' ? hr : ' '}
                  </td>
                  <td style={{ cursor: 'pointer' }}
                    className={hr.split(':')[1] === '00' && 'separator'}
                    onClick={() => createTask(hr)}
                    id={`schedule_${i}`}
                  >
                    {i === 0 && (<hr className='line-current-hour' style={{ display: lineHour ? 'block' : 'none', width: width, top: lineHourPos }} />)}

                    {dataSource.filter(a => `${moment(a.startAt).format('YYYY-MM-DD HH')}:${String(Math.floor(moment(a.startAt).format('mm') / 15) * 15).padStart(2, '0')}` === `${moment(date).format('YYYY-MM-DD')} ${hr}`).map((a, idx) => {
                      let points = 1;
                      const count = dataSource.filter((a, idx) => `${moment(a.startAt).format('YYYY-MM-DD HH')}:${String(Math.floor(moment(a.startAt).format('mm') / 15) * 15).padStart(2, '0')}` === `${moment(date).format('YYYY-MM-DD')} ${hr}`).length;

                      if (a) {
                        let endHr = Number(a.endAt.split(' ')[1].split(':')[0]);
                        let endMin = Number(Math.ceil(a.endAt.split(' ')[1].split(':')[1] / 15) * 15);

                        if (endMin >= 60) {
                          endHr = endHr + 1;
                          endMin = endMin - 60;
                        }

                        const end = lines.findIndex(b => b === `${String(endHr).padStart(2, 0)}:${String(endMin).padStart(2, '0')}`);
                        const start = lines.findIndex(b => b === `${a.startAt.split(' ')[1].split(':')[0]}:${String(Math.floor(a.startAt.split(' ')[1].split(':')[1] / 15) * 15).padStart(2, '0')}`);

                        points = end - start;
                      }

                      if (points <= 1) points = 1;

                      let left = 0;
                      let fWidth = width;

                      if (idx > 0) {
                        left = (width / count) * idx;
                        fWidth = width - left;
                      }

                      return (
                        <Tooltip title={a.projectName} placement='topLeft'>
                          <Task
                            style={{ height: points * lineHeight, width: fWidth, marginLeft: left }}
                            onClick={() => updateTask(a)}
                            className={`${(Number(a.finished) === 1) && `finished`} ${(Number(a.type) === 1 && Number(a.finished) !== 1) && `in-progress`}`}
                          >
                            <h4>
                              {(Number(a.finished) === 1) ? <CheckCircleFilled /> : (Number(a.type) === 1 && Number(a.finished) !== 1) ? <PlayCircleOutlined /> : <HourglassOutlined />}
                              {a.name}
                            </h4>
                            <span>
                              {`${a.startAt.split(' ')[1].split(':')[0]}:${a.startAt.split(' ')[1].split(':')[1]} - ${a.endAt.split(' ')[1].split(':')[0]}:${a.endAt.split(' ')[1].split(':')[1]}`}
                            </span>
                          </Task>
                        </Tooltip>
                      )
                    })}
                    <span className='content'></span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>

      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} hour={hour} date={date} />
      <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} id={id} date={date} />
    </Container>
  );
}
