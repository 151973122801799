import styled from 'styled-components';
import { Card as _Card } from 'antd';

export const Container = styled.div`
  h1 {
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--primary-text);
    text-align: left;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const NoContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 5rem;
`;

export const Card = styled(_Card)`
  background-color: var(--body-background);
`;
