export function taskHistoryIndexRequest(projectId, taskId, filters) {
  return {
    type: '@taskHistory/INDEX_REQUEST',
    payload: { projectId, taskId, filters },
  };
}

export function taskHistoryIndexSuccess(data, page, count) {
  return {
    type: '@taskHistory/INDEX_SUCCESS',
    payload: { data, page, count },
  };
}

export function taskHistoryIndexFailure() {
  return {
    type: '@taskHistory/INDEX_FAILURE',
  };
}

export function taskHistoryCreateRequest(data) {
  return {
    type: '@taskHistory/CREATE_REQUEST',
    payload: { data },
  };
}

export function taskHistoryCreateSuccess(data) {
  return {
    type: '@taskHistory/CREATE_SUCCESS',
    payload: { data },
  };
}

export function taskHistoryCreateFailure() {
  return {
    type: '@taskHistory/CREATE_FAILURE',
  };
}

export function taskHistoryReadRequest(data) {
  return {
    type: '@taskHistory/READ_REQUEST',
    payload: { data },
  };
}

export function taskHistoryReadSuccess(data) {
  return {
    type: '@taskHistory/READ_SUCCESS',
    payload: { data },
  };
}

export function taskHistoryReadFailure() {
  return {
    type: '@taskHistory/READ_FAILURE',
  };
}

export function taskHistoryUpdateRequest(data) {
  return {
    type: '@taskHistory/UPDATE_REQUEST',
    payload: { data },
  };
}

export function taskHistoryUpdateSuccess(data) {
  return {
    type: '@taskHistory/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function taskHistoryUpdateFailure() {
  return {
    type: '@taskHistory/UPDATE_FAILURE',
  };
}

export function taskHistoryDeleteRequest(projectId, taskId, id) {
  return {
    type: '@taskHistory/DELETE_REQUEST',
    payload: { projectId, taskId, id },
  };
}

export function taskHistoryDeleteSuccess() {
  return {
    type: '@taskHistory/DELETE_SUCCESS',
  };
}

export function taskHistoryDeleteFailure() {
  return {
    type: '@taskHistory/DELETE_FAILURE',
  };
}

export function taskHistoryClean() {
  return {
    type: '@taskHistory/CLEAN'
  };
}
