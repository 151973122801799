import styled from 'styled-components';
import { Select } from 'antd';

export const Container = styled.div`
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 0.6rem;
  }
`;

export const NoContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 5rem;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ProjectInfo = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary-text);
  text-align: left;
`;

export const Combobox = styled(Select)`
  width: 100%;

  + svg {
    cursor: pointer;
  }
`;