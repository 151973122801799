export const listYesNo = [{ key: 'true', value: "Sim" }, { key: 'false', value: "Não" }];

export const avatarColors = [
  '#eb2f96',
  '#f5222d',
  '#fadb14',
  '#fa8c16',
  '#13c2c2',
  '#52c41a',
  '#1890ff',
  '#722ed1',
  '#2f54eb',
  '#eb2f96',
  '#fa541c',
  '#faad14',
  '#a0d000'
];

export const emailType = [
  { type: 'cobranca_boleto_novo', description: 'Cobrança - Novo boleto' },
  { type: 'cobranca_boleto_reenvio', description: 'Cobrança - Reenvio de boleto' },
  { type: 'cobranca_aviso_suspensao', description: 'Cobrança - Aviso de Suspensão' },
  { type: 'cobranca_aviso_suspenso', description: 'Cobrança - Suspenso' },
  { type: 'cobranca_aviso_remocao', description: 'Cobrança - Aviso de Remoção' },
];

export const taskState = [
  { id: 0, description: 'Na fila', type: 0, color: '#ccc' },
  { id: 1, description: 'Em Produção', type: 1, color: '#2f54eb' },
  { id: 2, description: 'Bloqueado', type: 3, color: '#f5222d' },
  { id: 3, description: 'Revisão', type: 4, color: '#facf14' },
  { id: 4, description: 'Concluído', type: 2, color: '#52c41a' },
];

export const taskType = [
  { id: 1, description: 'Tarefa', color: '#fadb14' },
  { id: 2, description: 'Bug', color: '#ff0014' },
];

export const taskTab = [
  { id: 1, key: 'main', description: 'Detalhes' },
  { id: 2, key: 'comment', description: 'Comentários' },
  { id: 3, key: 'hour', description: 'Horas trabalhadas' },
  { id: 4, key: 'history', description: 'Histórico' },
];