import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import { dashboardIndexSuccess, dashboardIndexFailure } from './actions';

export function* onIndex({ payload }) {
  try {
    const { filters } = payload;

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `dashboard${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Falha ao obter os dados do dashboard';

    yield put(dashboardIndexSuccess(result.data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(dashboardIndexFailure());
  }
}

export default all([
  takeLatest('@dashboard/INDEX_REQUEST', onIndex),
]);
