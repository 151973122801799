import styled from 'styled-components';
import { Table, Button as _Button } from 'antd';

import _DataList from '~/components/DataList';

export const Container = styled.div`

`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  transition: height 0.2 ease;
`;

export const DataList = styled(_DataList)`
  th, td {
    padding: 0.5rem !important;
  }
  
  .ant-table-column-title {
    font-weight: 700;
  }
`;

export const Column = styled(Table.Column)`
  
`;

export const MenuButton = styled(_Button)`
  margin: 5px 0 0;
  font-weight: bold;
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 0;
  font-size: 16px;
  transition: background 0.2s;
`;
