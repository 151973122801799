import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: var(--column-container-height);
  overflow: hidden;
  
  .scrollbar-container {
    width: 100%;
  }

  .scroll-x {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 0.7rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #ededed;
  border-radius: 0.5rem;
  padding: 0.6rem 0;
  width: 100%;
  min-width: 250px;
  max-width: 275px;
  height: var(--column-container-height);
  margin-right: 0.6rem;
  overflow: hidden;

  .card-header {
    cursor: default;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 700;
    border-bottom: 1px solid var(--border-secondary);
    padding: 0.4rem 0;
    margin-top: -0.6rem;
    background-color: ${darken(0.05, '#F5F5F5')};
    align-items: center;
    justify-content: center;

    .badge {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      margin-right: 0.4rem;
    }

    span {
      display: flex;
      flex-direction: row;
      padding: 0 0.2rem;
      align-items: center;
      justify-content: center;

      &:last-child {
        font-size: 0.6rem;
      }
    }
  }

  .card-container {
    padding: 0.6rem;
  }
`;
