import styled from 'styled-components';
import { Modal as _Modal } from 'antd';

export const Container = styled.div`
  
`;

export const Modal = styled(_Modal)`
  height: 95vh;
  
  .ant-modal-header {
    background-color: var(--modal-header-background);
    
    .ant-modal-title {
      font-weight: 700;
    }
  }

  .ps__rail-x {
    display: none;
  }

  .ps__rail-y {
    right: 0 !important;
    left: auto !important;
  }
`;
