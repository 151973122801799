export function projectTeamIndexRequest(projectId, filters) {
  return {
    type: '@projectTeams/INDEX_REQUEST',
    payload: { projectId, filters },
  };
}

export function projectTeamIndexSuccess(data) {
  return {
    type: '@projectTeams/INDEX_SUCCESS',
    payload: { data },
  };
}

export function projectTeamIndexFailure() {
  return {
    type: '@projectTeams/INDEX_FAILURE',
  };
}

export function projectTeamCreateRequest(data) {
  return {
    type: '@projectTeams/CREATE_REQUEST',
    payload: { data },
  };
}

export function projectTeamCreateSuccess(data) {
  return {
    type: '@projectTeams/CREATE_SUCCESS',
    payload: { data },
  };
}

export function projectTeamCreateFailure() {
  return {
    type: '@projectTeams/CREATE_FAILURE',
  };
}

export function projectTeamReadRequest(id, projectId) {
  return {
    type: '@projectTeams/READ_REQUEST',
    payload: { id, projectId },
  };
}

export function projectTeamReadSuccess(data) {
  return {
    type: '@projectTeams/READ_SUCCESS',
    payload: { data },
  };
}

export function projectTeamReadFailure() {
  return {
    type: '@projectTeams/READ_FAILURE',
  };
}

export function projectTeamUpdateRequest(data) {
  return {
    type: '@projectTeams/UPDATE_REQUEST',
    payload: { data },
  };
}

export function projectTeamUpdateSuccess(data) {
  return {
    type: '@projectTeams/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function projectTeamUpdateFailure() {
  return {
    type: '@projectTeams/UPDATE_FAILURE',
  };
}

export function projectTeamDeleteRequest(id, projectId) {
  return {
    type: '@projectTeams/DELETE_REQUEST',
    payload: { id, projectId },
  };
}

export function projectTeamDeleteSuccess() {
  return {
    type: '@projectTeams/DELETE_SUCCESS',
  };
}

export function projectTeamDeleteFailure() {
  return {
    type: '@projectTeams/DELETE_FAILURE',
  };
}
