import produce from 'immer';

const INITIAL_STATE = {
  result: [],
  loading: false,
};

export default function reportCompletedWork(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@reports/COMPLETED_WORK/REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@reports/COMPLETED_WORK/SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.result = action.payload.data;
      });

    case '@reports/COMPLETED_WORK/FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.result = [];
      });

    default:
      return state;
  }
}
