import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Input, Avatar, Select, Space, Tooltip, DatePicker } from 'antd';
import PerfectScroll from 'react-perfect-scrollbar';
import ReactQuill from 'react-quill';
import moment from 'moment';

import Modal from '~/components/Modal';

import { taskType, taskState, avatarColors } from '~/helpers/lists';
import { isNullOrEmpty } from '~/helpers/util';

import { taskCreateRequest } from '~/store/modules/task/actions';
import { userIndexRequest } from '~/store/modules/user/actions';
import { taskCommentClean } from '~/store/modules/taskComment/actions';

import 'react-quill/dist/quill.snow.css';
import { UserOutlined } from '@ant-design/icons';
import { Form } from './styles';

export default function Create({ projectId, sprintId, isVisible, setIsVisible, users }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const loading = useSelector(store => store.task.saving);
  const error = useSelector(store => store.task.error);

  const [avatar, setAvatar] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  function onFinish(values) {
    let { userId, name, type, state, remainingWork, description, commentary, deadlineAt } = values;

    if (remainingWork) {
      const _d = remainingWork.split(':');

      const hr = Number(_d[0]);
      const min = Number(_d[1]);

      remainingWork = (hr * 60) + min;
    }

    dispatch(taskCreateRequest({
      projectId,
      sprintId,
      userId,
      name,
      type,
      state,
      remainingWork,
      description,
      commentary,
      deadlineAt: isNullOrEmpty(deadlineAt) ? undefined : moment(deadlineAt).format("YYYY-MM-DD HH:mm")
    }));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({
      userId: undefined,
      name: '',
      type: 1,
      state: 0,
      remainingWork: undefined,
      description: '',
      commentary: '',
      deadlineAt: undefined
    });

    dispatch(taskCommentClean());
  }

  useEffect(() => {
    dispatch(userIndexRequest());
  }, []);

  useMemo(() => {
    if (isSaving && !loading) {
      setIsSaving(false);

      if (!error) {
        setIsVisible(false);
        onReset();
      }
    }
  }, [loading]);

  useMemo(() => {
    var i = 0;

    var words = profile.name.split(' ');
    if (words.length <= 1) words = profile.name.split('_');
    if (words.length <= 1) words = profile.name.split('/');
    if (words.length <= 1) words = profile.name.split('|');
    if (words.length <= 1) words = profile.name.split('-');

    const initials = words.map(w => {
      if (i >= 2) return '';
      if (w.trim().length <= 0) return '';

      const letter = w.trim().substring(0, 1);

      if (!letter.match(/[a-z]/i)) return '';

      i++;

      return letter.toUpperCase();
    });

    const avatarColor = avatarColors[moment(profile.createdAt).unix() % avatarColors.length];

    setAvatar({ initials, color: avatarColor });
  }, [profile]);

  return (
    <Modal
      title="Nova tarefa"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={800}
      processing={loading}
      bodyStyle={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }}
      style={{ top: 0, margin: '15px auto' }}
    >
      <PerfectScroll style={{ height: '100%' }}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            userId: undefined,
            state: 0,
            type: 1,
          }}
          onFinish={onFinish}>
          <Row gutter={8}>
            <Col xs={24} md={5}>
              <Form.Item
                label="Tipo"
                name="type"
                tooltip="Escolha o tipo da tarefa"
              >
                <Select>
                  {taskType.map(e => (
                    <Select.Option key={e.id} value={e.id}>{e.description}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={15}>
              <Form.Item
                label="Título"
                name="name"
                rules={[{ required: true, message: 'Título é obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={4}>
              <Form.Item
                label="Esforço"
                name="remainingWork"
                tooltip="Horas estimadas"
              >
                <Input type='time' />
              </Form.Item>
            </Col>

            <Col xs={24} md={5}>
              <Form.Item
                label="Status"
                name="state"
                required
                tooltip="Escolha o status da tarefa"
              >
                <Select>
                  {taskState.map(e => (
                    <Select.Option key={e.id} value={e.id}>{e.description}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={13}>
              <Form.Item
                label="Responsável"
                name="userId"
                tooltip="Quem está designado para esta tarefa"
              >
                <Select>
                  {users?.map(u => (
                    <Select.Option key={u.id} value={u.id}>
                      <Space>
                        <Avatar size="18" icon={u.icon} style={{ backgroundColor: u.color, verticalAlign: 'middle' }}>
                          {u.short}
                        </Avatar>
                        <span>{u.name}</span>
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                label="Prazo"
                name="deadlineAt"
                tooltip="Defina uma data de entrega desta tarefa"
              >
                <DatePicker showTime format="DD/MM/YYYY HH:mm" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Descrição"
                name="description"
              >
                <ReactQuill theme="snow" modules={editorModules} formats={editorFormats} />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label={
                  <Tooltip title={`Comentar como ${profile.name}`}>
                    <Space>
                      <Avatar size="18" style={{ backgroundColor: avatar?.color, verticalAlign: 'middle' }}>
                        {avatar?.initials}
                      </Avatar>
                      <span>Comentário</span>
                    </Space>
                  </Tooltip>
                }
                name="commentary"
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PerfectScroll>
    </Modal>
  );
}
