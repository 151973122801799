import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { CatchErrors } from '../common';

import {
  projectTeamIndexRequest, projectTeamIndexSuccess, projectTeamIndexFailure, projectTeamCreateSuccess,
  projectTeamCreateFailure, projectTeamReadSuccess, projectTeamReadFailure, projectTeamUpdateSuccess,
  projectTeamUpdateFailure, projectTeamDeleteSuccess, projectTeamDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    const { projectId } = payload;

    const response = yield call(api.get, `projects/${projectId}/teams`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectTeamIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectTeamIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { projectId } = payload.data;

    const response = yield call(api.post, `projects/${projectId}/teams`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectTeamCreateSuccess(data));
    yield put(projectTeamIndexRequest(projectId));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectTeamCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id, projectId } = payload;

    const response = yield call(api.get, `projects/${projectId}/teams/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectTeamReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectTeamReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id, projectId } = payload.data;

    const response = yield call(api.post, `projects/${projectId}/teams/${id}`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectTeamUpdateSuccess(data));
    yield put(projectTeamIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectTeamUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id, projectId } = payload;

    const response = yield call(api.delete, `projects/${projectId}/teams/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(projectTeamDeleteSuccess());
    yield put(projectTeamIndexRequest(projectId));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectTeamDeleteFailure());
  }
}

export default all([
  takeLatest('@projectTeams/INDEX_REQUEST', onIndex),
  takeLatest('@projectTeams/CREATE_REQUEST', onCreate),
  takeLatest('@projectTeams/READ_REQUEST', onRead),
  takeLatest('@projectTeams/UPDATE_REQUEST', onUpdate),
  takeLatest('@projectTeams/DELETE_REQUEST', onDelete),
]);