import { combineReducers } from 'redux';

import auth from './auth/reducer';
import dashboard from './dashboard/reducer';
import mailSend from './mailSend/reducer';
import mailTerms from './mailTerms/reducer';
import menuProject from './menuProject/reducer';
import project from './project/reducer';
import projectTeam from './projectTeam/reducer';
import schedule from './schedule/reducer';
import sprint from './sprint/reducer';
import task from './task/reducer';
import taskComment from './taskComment/reducer';
import taskHistory from './taskHistory/reducer';
import taskHour from './taskHour/reducer';
import taskCompletedHour from './taskCompletedHour/reducer';
import user from './user/reducer';
import reportCompletedWork from './reports/completedWork/reducer';

export default combineReducers({
  auth,
  dashboard,
  mailSend,
  mailTerms,
  menuProject,
  project,
  projectTeam,
  schedule,
  sprint,
  task,
  taskComment,
  taskHistory,
  taskHour,
  taskCompletedHour,
  user,
  reportCompletedWork
});