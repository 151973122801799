export function taskCommentIndexRequest(projectId, taskId, filters) {
  return {
    type: '@taskComments/INDEX_REQUEST',
    payload: { projectId, taskId, filters },
  };
}

export function taskCommentIndexSuccess(data) {
  return {
    type: '@taskComments/INDEX_SUCCESS',
    payload: { data },
  };
}

export function taskCommentIndexFailure() {
  return {
    type: '@taskComments/INDEX_FAILURE',
  };
}

export function taskCommentCreateRequest(data) {
  return {
    type: '@taskComments/CREATE_REQUEST',
    payload: { data },
  };
}

export function taskCommentCreateSuccess(data) {
  return {
    type: '@taskComments/CREATE_SUCCESS',
    payload: { data },
  };
}

export function taskCommentCreateFailure() {
  return {
    type: '@taskComments/CREATE_FAILURE',
  };
}

export function taskCommentReadRequest(data) {
  return {
    type: '@taskComments/READ_REQUEST',
    payload: { data },
  };
}

export function taskCommentReadSuccess(data) {
  return {
    type: '@taskComments/READ_SUCCESS',
    payload: { data },
  };
}

export function taskCommentReadFailure() {
  return {
    type: '@taskComments/READ_FAILURE',
  };
}

export function taskCommentUpdateRequest(data) {
  return {
    type: '@taskComments/UPDATE_REQUEST',
    payload: { data },
  };
}

export function taskCommentUpdateSuccess(data) {
  return {
    type: '@taskComments/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function taskCommentUpdateFailure() {
  return {
    type: '@taskComments/UPDATE_FAILURE',
  };
}

export function taskCommentDeleteRequest(projectId, taskId, id) {
  return {
    type: '@taskComments/DELETE_REQUEST',
    payload: { projectId, taskId, id },
  };
}

export function taskCommentDeleteSuccess() {
  return {
    type: '@taskComments/DELETE_SUCCESS',
  };
}

export function taskCommentDeleteFailure() {
  return {
    type: '@taskComments/DELETE_FAILURE',
  };
}

export function taskCommentClean() {
  return {
    type: '@taskComments/CLEAN'
  };
}
