import styled from 'styled-components';
import _DataList from '~/components/DataList';
import { Form as _Form, Button as _Button } from 'antd';

export const Form = styled(_Form)`
  .ql-editor {
    min-height: 120px;
    max-height: 200px;
  }

  .ant-skeleton-element, .ant-skeleton-button {
    width: 100%;
  }
`;

export const Button = styled(_Button)`
  padding: 1px 7px;
  height: auto;
  border-radius: 4px;
`;

export const DataList = styled(_DataList)`
  margin-bottom: 2rem;

  .ant-table-thead {
    display: none !important;
  }

  .ant-col {
    font-size: 0.8rem;
  }
`;
