import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';

import { Modal } from './styles';

export default function Feedback({ isVisible, setIsVisible }) {
  const profile = useSelector(store => store.auth.profile);
  return (
    <Modal
      title="Enviar feedback"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { }}
      maskClosable={true}
      width={500}
      footer={() => { }}
    >
      <iframe src={`https://feedback.webstationdesign.com.br/bbf6fb3f6b0ac6ea2395554867bf8078?userId=${profile.id}}`} frameborder="0" scrolling="no" height="260"></iframe>
    </Modal>
  );
};