import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import dashboard from './dashboard/sagas';
import mailSend from './mailSend/sagas';
import mailTerms from './mailTerms/sagas';
import menuProject from './menuProject/sagas';
import project from './project/sagas';
import projectTeam from './projectTeam/sagas';
import schedule from './schedule/sagas';
import sprint from './sprint/sagas';
import task from './task/sagas';
import taskComment from './taskComment/sagas';
import taskHistory from './taskHistory/sagas';
import taskHour from './taskHour/sagas';
import taskCompletedHour from './taskCompletedHour/sagas';
import user from './user/sagas';
import reportCompletedWork from './reports/completedWork/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    dashboard,
    mailSend,
    mailTerms,
    menuProject,
    project,
    projectTeam,
    schedule,
    sprint,
    task,
    taskComment,
    taskHistory,
    taskHour,
    taskCompletedHour,
    user,
    reportCompletedWork
  ]);
}
