import React, { useState, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spin, Col, Row, Button, Select, Tooltip } from 'antd';
import moment from 'moment';

import ModalCreate from './Create';
import ModalUpdate from './Update';
import TaskCreate from '../Task/Create';
import TaskUpdate from '../Task/Update';
import List from './List';

import { avatarColors, taskState } from '~/helpers/lists';
import { isNullOrEmpty } from '~/helpers/util';

import { projectReadRequest } from '~/store/modules/project/actions';
import { sprintIndexRequest, sprintReadRequest } from '~/store/modules/sprint/actions';
import { taskReadRequest } from '~/store/modules/task/actions';
import { taskHourIndexRequest } from '~/store/modules/taskHour/actions';
import { projectTeamIndexRequest } from '~/store/modules/projectTeam/actions';

import { PlusOutlined, PlusCircleFilled, UserOutlined, EditOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Container, NoContent, Content, FilterContent, ProjectInfo, ItemGroup } from './styles';

export default function Sprint(props) {
  const dispatch = useDispatch();

  const { projectId, id, idTask, tabId } = props?.match?.params;
  const sprintId = !id ? 'current' : id;
  const levelAdm = 7;

  const profile = useSelector(store => store.auth.profile);
  const project = useSelector(store => store.project.registry);
  const sprints = useSelector(store => store.sprint.list);
  const sprint = useSelector(store => store.sprint.registry);
  const sprintLoading = useSelector(store => store.sprint.loading);
  const usersLoading = useSelector(store => store.projectTeam.loading);
  const usersData = useSelector(store => store.projectTeam.list);

  const [currentSprint, setCurrentSprint] = useState(sprintId);
  const [users, setUsers] = useState([]);
  const [otherSprints, setOtherSprints] = useState([]);
  const [sprintFiled, setSprintFiled] = useState(false);

  const [loading, setLoading] = useState(true);
  const [curSprint, setCurSprint] = useState();
  const [taskId, setTaskId] = useState();
  const [selectedTab, setSelectedTab] = useState('main');
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [isVisibleTaskCreate, setIsVisibleTaskCreate] = useState(false);
  const [isVisibleTaskUpdate, setIsVisibleTaskUpdate] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [dragCardState, setDragCardState] = useState({
    disabled: true,
    dragId: undefined,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  });

  const { bounds, dragId, disabled } = dragCardState;

  let gridArray = [];

  taskState.map(s => {
    gridArray.push(React.createRef());
  });

  const gridRef = useRef(gridArray);
  const cardRef = useRef({});

  function handleStop(e, data, sprintId) {
    console.log(`Card dropped in sprint ${sprintId}`);
  }

  function onSprintEdit() {
    if (!sprint || isNullOrEmpty(sprint.id)) toast.error('Selecione uma Linha do tempo');

    setIsVisibleUpdate(true);
  }

  function openTask(e) {
    setTaskId(e.id);
    dispatch(taskReadRequest(e));
    setIsVisibleTaskUpdate(true);
  }

  useMemo(() => {
    setSelectedTab(tabId);
  }, [tabId]);

  useMemo(() => {
    dispatch(sprintIndexRequest(projectId, [{ filed: 0 }]));
    dispatch(projectTeamIndexRequest(projectId));
    dispatch(projectReadRequest(projectId));

    setLoading(true);
  }, [currentSprint, projectId]);

  useMemo(() => {
    dispatch(sprintReadRequest(projectId, currentSprint));
    dispatch(taskHourIndexRequest(projectId, [{ sprintId: currentSprint }]));

    if (idTask && id === currentSprint) openTask({ id: idTask, projectId });
  }, [currentSprint, projectId, idTask]);

  useMemo(() => {
    setCurrentSprint(id);
  }, [id]);

  useMemo(() => {
    if (sprint !== curSprint) {
      setCurSprint(sprint);
    }
  }, [sprint]);

  useMemo(() => {
    if (!sprintLoading) setLoading(sprintLoading);
  }, [sprintLoading]);

  useMemo(() => {
    const result = [{
      id: undefined,
      name: 'Nenhum responsável',
      short: undefined,
      color: undefined,
      icon: <UserOutlined />
    }];

    const us = usersData?.map(u => {
      var i = 0;

      var words = u.name.split(' ');
      if (words.length <= 1) words = u.name.split('_');
      if (words.length <= 1) words = u.name.split('/');
      if (words.length <= 1) words = u.name.split('|');
      if (words.length <= 1) words = u.name.split('-');

      const initials = words.map(w => {
        if (i >= 2) return '';
        if (w.trim().length <= 0) return '';

        const letter = w.trim().substring(0, 1);

        if (!letter.match(/[a-z]/i)) return '';

        i++;

        return letter.toUpperCase();
      });

      const avatarColor = avatarColors[moment(u.createdAt).unix() % avatarColors.length];

      result.push({
        id: u.userId,
        name: u.name,
        short: initials,
        color: avatarColor,
        icon: undefined
      });
    });

    setUsers(result);
  }, [usersData]);

  useMemo(() => {
    if (!sprints || sprints.length <= 0) setOtherSprints([]);

    let result = sprints.filter(s => s.id !== sprint?.id);
    result = result?.map(s => { return { key: s.id, name: s.name } });

    if (!result || result.length <= 0) setOtherSprints([]);

    setOtherSprints(result);
  }, [sprints, sprint]);

  return (
    <Container>
      {loading && (
        <NoContent>
          <Spin />
        </NoContent>
      )}

      {!loading && (
        <Row>
          <Col xs={24}>
            <ProjectInfo>
              {project?.name}
            </ProjectInfo>
          </Col>

          {(sprints && sprints.length > 0) && (
            <FilterContent>
              <Row>
                <Col xs={24} md={12} lg={8}>
                  <div className='filter-group sprint-list'>
                    <label>Linha do tempo:</label>

                    <ItemGroup compact>
                      <Select value={sprint?.id} style={{ textAlign: 'left' }} onChange={e => setCurrentSprint(e)}>
                        {sprints?.map(s => {
                          return (
                            <Select.Option key={s.id} value={s.id}>
                              <div>
                                <span>{s.name}</span>
                              </div>
                            </Select.Option>
                          );
                        })}
                      </Select>

                      {Number(profile.level) >= 7 && (
                        <>
                          <Tooltip title="Editar linha do tempo">
                            <EditOutlined onClick={() => onSprintEdit()} />
                          </Tooltip>

                          <Tooltip title="Nova linha do tempo">
                            <PlusCircleFilled onClick={() => setIsVisibleCreate(true)} />
                          </Tooltip>

                          {sprintFiled ? (
                            <Tooltip title="Ocultar arquivadas">
                              <EyeInvisibleOutlined onClick={() => { setSprintFiled(false); dispatch(sprintIndexRequest(projectId, [{ filed: 0 }])); }} />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Exibir arquivadas">
                              <EyeOutlined onClick={() => { setSprintFiled(true); dispatch(sprintIndexRequest(projectId)); }} />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </ItemGroup>
                  </div>
                </Col>

                <Col xs={0} sm={0} md={4} lg={12} className='text-center'>

                </Col>

                <Col xs={24} md={8} lg={4}>
                  <Button icon={<PlusOutlined />} onClick={() => setIsVisibleTaskCreate(true)} htmlType='button' type='primary'>Adcionar tarefa</Button>
                </Col>
              </Row>
            </FilterContent>
          )}

          {(!sprint || !sprint?.id) ? (
            <NoContent>
              <Alert
                message={<h1>Sua linha do tempo não foi configurada.</h1>}
                description={(
                  <span><Link to="#" onClick={() => setIsVisibleCreate(true)}>Crie</Link> uma sprint com data de início e término para poder adicionar tarefas à sua linha do tempo.</span>
                )}
                type="warning"
                showIcon
              />
            </NoContent>
          ) : (
            <Content>
              <List
                sprint={sprint}
                projectId={projectId}
                sprintId={sprintId}
                taskId={taskId}
                setTaskId={setTaskId}
                levelAdm={levelAdm}
                otherSprints={otherSprints}
                users={users}
                playlist={playlist}
                setPlaylist={setPlaylist}
                isVisibleTaskUpdate={isVisibleTaskUpdate}
                setIsVisibleTaskUpdate={setIsVisibleTaskUpdate}
                setTabId={setSelectedTab}
              />
            </Content>
          )}
        </Row>
      )}

      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} projectId={projectId} />
      <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} id={sprint?.id} projectId={projectId} />

      <TaskCreate isVisible={isVisibleTaskCreate} setIsVisible={setIsVisibleTaskCreate} projectId={projectId} sprintId={sprint?.id} users={users} />
      <TaskUpdate isVisible={isVisibleTaskUpdate} setIsVisible={setIsVisibleTaskUpdate} projectId={projectId} sprintId={sprint?.id} id={taskId} users={users} tabId={selectedTab} />
    </Container >
  );
};
