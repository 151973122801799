import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import {
  projectIndexRequest, projectIndexSuccess, projectIndexFailure, projectCreateSuccess, projectCreateFailure,
  projectReadSuccess, projectReadFailure, projectUpdateSuccess, projectUpdateFailure, projectDeleteSuccess,
  projectDeleteFailure
} from './actions';
import { menuProjectIndexRequest } from '../menuProject/actions';

export function* onIndex({ payload }) {
  try {
    const { filters } = payload;

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `projects${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const response = yield call(api.post, 'projects', payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectCreateSuccess(data));
    yield put(projectIndexRequest());
    yield put(menuProjectIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `projects/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id } = payload.data;

    const response = yield call(api.post, `projects/${id}`, payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(projectUpdateSuccess(data));
    yield put(projectIndexRequest());

    if (result.message) toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `projects/delete/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(projectDeleteSuccess());
    yield put(projectIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(projectDeleteFailure());
  }
}

export default all([
  takeLatest('@projects/INDEX_REQUEST', onIndex),
  takeLatest('@projects/CREATE_REQUEST', onCreate),
  takeLatest('@projects/READ_REQUEST', onRead),
  takeLatest('@projects/UPDATE_REQUEST', onUpdate),
  takeLatest('@projects/DELETE_REQUEST', onDelete),
]);