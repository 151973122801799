import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import {
  taskHourIndexRequest, taskHourIndexSuccess, taskHourIndexFailure, taskHourCreateSuccess, taskHourCreateFailure,
  taskHourReadSuccess, taskHourReadFailure, taskHourUpdateSuccess, taskHourUpdateFailure, taskHourDeleteSuccess,
  taskHourDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `projects/${id}/tasks/hours`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskHourIndexSuccess(data));
  }
  catch (err) {
    yield put(taskHourIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const response = yield call(
      api.post,
      `projects/${payload.data?.projectId}/tasks/${payload.data?.taskId}/hours`,
      payload.data
    );

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskHourCreateSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskHourCreateFailure());
  }
  finally {
    yield put(taskHourIndexRequest(payload.data?.projectId));
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `projects/${payload.data?.projectId}/taskHours/${payload.data?.id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskHourReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskHourReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const response = yield call(
      api.post,
      `projects/${payload.data?.projectId}/tasks/${payload.data?.taskId}/hours/${payload.data?.id}`,
      payload.data);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(taskHourUpdateSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskHourUpdateFailure());
  }
  finally {
    yield put(taskHourIndexRequest(payload.data?.projectId));
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `taskHours/delete/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(taskHourDeleteSuccess());
    yield put(taskHourIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(taskHourDeleteFailure());
  }
}

export default all([
  takeLatest('@taskHours/INDEX_REQUEST', onIndex),
  takeLatest('@taskHours/CREATE_REQUEST', onCreate),
  takeLatest('@taskHours/READ_REQUEST', onRead),
  takeLatest('@taskHours/UPDATE_REQUEST', onUpdate),
  takeLatest('@taskHours/DELETE_REQUEST', onDelete),
]);