export function mailSendCreateRequest(data) {
  return {
    type: '@mailSend/CREATE_REQUEST',
    payload: { data },
  };
}

export function mailSendCreateSuccess(data) {
  return {
    type: '@mailSend/CREATE_SUCCESS',
  };
}

export function mailSendCreateFailure() {
  return {
    type: '@mailSend/CREATE_FAILURE',
  };
}
