import axios from 'axios';
import * as dotenv from 'dotenv';

import { store } from '~/store';

import { signFailure } from '~/store/modules/auth/actions';

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';

api.interceptors.response.use((response) => {
  const state = store.getState();

  return response;
}, (error) => {
  if (error?.response?.status == 401 || error?.response?.status == 403) {

    store.dispatch(signFailure());
    window.location.href = '/login?error=' + error?.response?.data?.error;
  }

  return error;
});

export const apiLog = axios.create({
  baseURL: process.env.REACT_APP_LOG_URL
});

export default api;
