import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  registry: {},
  loading: false,
  saving: false,
  error: false,
  page: 1,
  count: 0,
};

export default function taskComment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@taskComments/INDEX_REQUEST':
    case '@taskComments/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@taskComments/CREATE_REQUEST':
    case '@taskComments/UPDATE_REQUEST':
      return produce(state, draft => {
        draft.saving = true;
        draft.error = false;
      });

    case '@taskComments/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@taskComments/CREATE_SUCCESS':
    case '@taskComments/UPDATE_SUCCESS':
    case '@taskComments/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.saving = false;
      });

    case '@taskComments/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
        draft.page = action.payload.page;
        draft.count = action.payload.count;
      });

    case '@taskComments/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.registry = action.payload.data;
      });

    case '@taskComments/INDEX_FAILURE':
    case '@taskComments/CREATE_FAILURE':
    case '@taskComments/UPDATE_FAILURE':
    case '@taskComments/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.saving = false;
      });

    case '@taskComments/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.registry = {};
      });

    case '@taskComments/CLEAN':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.list = [];
        draft.registry = {};
      });

    default:
      return state;
  }
}
