import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, DatePicker, Select, Space, Avatar, Collapse, Progress, Tooltip } from 'antd';
import moment from 'moment';

import { taskType, avatarColors } from '~/helpers/lists';

import { reportCompletedWorkRequest } from '~/store/modules/reports/completedWork/actions';
import { userIndexRequest } from '~/store/modules/user/actions';

import { UserOutlined, ProjectOutlined, BugOutlined, SolutionOutlined } from '@ant-design/icons';
import { Container } from './styles';
import { FilterContent } from '../../Sprint/styles';

export default function CompletedWork() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.reportCompletedWork.loading);
  const result = useSelector(store => store.reportCompletedWork.result);
  const usersData = useSelector(store => store.user.list);

  const [users, setUsers] = useState([]);

  const [form] = Form.useForm();

  function onFinish(values) {
    const { startAt, endAt, userId } = values;

    let filters = [];

    filters.push({ startAt: moment(startAt).format('YYYY-MM-DD') });
    filters.push({ endAt: moment(endAt).format('YYYY-MM-DD') });

    if (userId) filters.push({ userId: userId });

    dispatch(reportCompletedWorkRequest(filters));
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    dispatch(userIndexRequest());
    //dispatch(reportCompletedWorkRequest());
  }, []);


  useMemo(() => {
    const result = [{
      id: undefined,
      name: 'Nenhum responsável',
      short: undefined,
      color: undefined,
      icon: <UserOutlined />
    }];

    const us = usersData?.map(u => {
      var i = 0;

      var words = u.name.split(' ');
      if (words.length <= 1) words = u.name.split('_');
      if (words.length <= 1) words = u.name.split('/');
      if (words.length <= 1) words = u.name.split('|');
      if (words.length <= 1) words = u.name.split('-');

      const initials = words.map(w => {
        if (i >= 2) return '';
        if (w.trim().length <= 0) return '';

        const letter = w.trim().substring(0, 1);

        if (!letter.match(/[a-z]/i)) return '';

        i++;

        return letter.toUpperCase();
      });

      const avatarColor = avatarColors[moment(u.createdAt).unix() % avatarColors.length];

      result.push({
        id: u.id,
        name: u.name,
        short: initials,
        color: avatarColor,
        icon: undefined
      });
    });

    setUsers(result);
  }, [usersData]);

  return (
    <Container>
      <h2>Relatório de Horas Trabalhadas</h2>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24} sm={12} md={6} lg={4}>
            <Form.Item
              label="Período de"
              name="startAt"
              rules={[{ required: true, message: 'Início é obrigatório' }]}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={4}>
            <Form.Item
              label="Até"
              name="endAt"
              rules={[{ required: true, message: 'Término é obrigatório' }]}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="" />
            </Form.Item>
          </Col>

          <Col xs={24} md={8} lg={8} style={{ marginBottom: -20 }}>
            <Form.Item
              label="Responsável"
              name="userId"
            >
              <Select>
                {users?.map(u => (
                  <Select.Option key={u.id} value={u.id}>
                    <Space>
                      <Avatar size="18" icon={u.icon} style={{ backgroundColor: u.color, verticalAlign: 'middle' }}>
                        {u.short}
                      </Avatar>
                      <span>{u.name}</span>
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={4} lg={8}>
            <Form.Item
              noStyle
            >
              <FilterContent style={{ marginTop: 24, padding: 0 }}>
                <Button htmlType='submit' type='primary' loading={loading}>Gerar relatório</Button>
              </FilterContent>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {(!loading && result && result.length > 0) && (
        <Collapse>
          {users?.map(u => {
            const details = result.filter(a => a.userId === u.id);

            if (details && details.length > 0) {
              let completedWork = details.reduce((accumulator, object) => {
                return accumulator + parseInt(object.completedWork);
              }, 0);

              const worked = completedWork;

              if (completedWork > 0) {
                let hr = Math.floor(Number(completedWork) / 3600);

                let min = Math.floor((Number(completedWork) - (Number(hr) * 3600)) / 60);
                if (min < 10) min = `0${min}`;

                completedWork = `${hr}h${Number(min) <= 0 ? '' : min}`;
              }

              const projects = details.map(e => e.projectId).filter((value, index, self) => self.indexOf(value) === index);

              let countProjects = projects?.length;
              if (!countProjects) countProjects = 0;

              let countTasks = details.filter(e => Number(e.taskType) === 1)?.map(e => e.taskId)?.filter((value, index, self) => self.indexOf(value) === index)?.length;
              if (!countTasks) countTasks = 0;

              let countBugs = details.filter(e => Number(e.taskType) === 2)?.map(e => e.taskId)?.filter((value, index, self) => self.indexOf(value) === index)?.length;
              if (!countBugs) countBugs = 0;

              return (
                <Collapse.Panel header={(
                  <Row>
                    <Col xs={24} md={20}>
                      <Space>
                        <Avatar size="18" icon={u.icon} style={{ backgroundColor: u.color, verticalAlign: 'middle' }}>
                          {u.short}
                        </Avatar>
                        <span>{u.name}</span>
                      </Space>
                    </Col>

                    <Col xs={24} md={4}>
                      <b>{completedWork}</b>
                    </Col>
                  </Row>
                )} key={u.id}>
                  <Row>
                    <Col xs={24} className='icons-info'>
                      <Tooltip title="Projetos"><span className='icon-project'><ProjectOutlined /> {countProjects}</span></Tooltip>
                      <Tooltip title="Tarefas"><span><SolutionOutlined style={{ color: taskType.find(a => Number(a.id) === 1)?.color }} /> {countTasks}</span></Tooltip>
                      <Tooltip title="Bugs"><span><BugOutlined style={{ color: taskType.find(a => Number(a.id) === 2)?.color }} /> {countBugs}</span></Tooltip>
                    </Col>
                  </Row>

                  {projects?.map(p => {
                    const project = details.find(e => e.projectId === p);
                    const projectWorked = details.filter(e => e.projectId === p).reduce((accumulator, object) => {
                      return accumulator + parseInt(object.completedWork);
                    }, 0);

                    const progress = parseFloat(Number(projectWorked) / Number(worked) * 100).toFixed(2);

                    let workedInfo = projectWorked;
                    if (workedInfo > 0) {
                      let hr = Math.floor(Number(workedInfo) / 3600);

                      let min = Math.floor((Number(workedInfo) - (Number(hr) * 3600)) / 60);
                      if (min < 10) min = `0${min}`;

                      workedInfo = `${hr}h${Number(min) <= 0 ? '' : min}`;
                    }

                    return (
                      <Row>
                        <Col xs={22} style={{ padding: '0.5rem' }}>
                          <span>{project?.projectName}</span>
                          <Tooltip title={`Horas trabalhadas: ${workedInfo}`}>
                            <Progress percent={progress} status="active" />
                          </Tooltip>
                        </Col>
                      </Row>
                    );
                  })}
                </Collapse.Panel>
              );
            }
          })}
        </Collapse>
      )}
    </Container>
  );
}