import styled from 'styled-components';
import Table from '~/components/DataList';

export const Container = styled.div`
  svg {
    font-size: 1rem;
    margin-right: 0.2rem;
  }
`;

export const DataList = styled(Table)`
  th, td {
    width: auto !important;
    text-align: center !important;
    font-weight: bold !important;

    &:first-child {
      width: 80px !important;
      text-align: left !important;
    }

    &:last-child {
      width: 100px !important;
      text-align: right !important;

      svg {
        cursor: pointer;
      }
    }
  }

  td {
    padding: 0.3rem !important;
    font-weight: normal !important;
  }

  svg {
    font-size: 1.1rem;
    margin-left: 0.3rem;
    color: #9ab;
  }

  .manually {
    font-weight: bold;
    color: var(--project-icon-epic);
  }

  .editable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: var(--anchor-primary-hover);
    }
  }

  .danger {
    svg {
      color: var(--danger);
      margin-right: 1rem;
    }
  }

  .data-list-footer {
    width: 100%;
    padding-right: calc(100px + 1.2rem);
    text-align: right;
    font-weight: bold !important;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

