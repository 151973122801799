import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import Draggable from 'react-draggable';
import PerfectScroll from 'react-perfect-scrollbar';

import Task from '../../Task/Card';

import { avatarColors, taskState } from '~/helpers/lists';

import { Container, Column } from './styles';

export default function List({ sprint, taskId, setTaskId, projectId, sprintId, levelAdm, otherSprints, users, playlist, setPlaylist, isVisibleTaskUpdate, setIsVisibleTaskUpdate, setTabId }) {
  const [position, setPosition] = useState({});

  let gridArray = [];

  taskState.map(s => {
    gridArray.push(React.createRef());
  });

  const gridRef = useRef(gridArray);

  function handleDrop(e, columnId) {
    e.preventDefault();
    const dragId = e.dataTransfer.getData('text/plain');

    console.log({ dragId, columnId, position });

    e.stopPropagation();

    // TODO
    // Aqui você pode adicionar lógica adicional para processar o drop na coluna
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  return (
    <Container>
      <PerfectScroll className='scroll-x'>
        {taskState.map(s => {
          const effort = sprint?.Tasks?.filter(e => Number(e.state) === Number(s.id))?.map(e => e.remainingWork);
          const sumEffort = effort?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0);

          let remainingWork = undefined;

          const hr = Math.floor(Number(sumEffort) / 60);
          let min = Number(sumEffort) - (hr * 60);
          if (min > 0 && min < 10) min = `0${min}`;

          remainingWork = `${hr}h${min <= 0 ? '' : min}`;

          let completedWork = undefined;

          if (playlist && playlist.length > 0) {
            const filtered = playlist.filter(
              e1 => sprint?.Tasks?.filter(e => Number(e.state) === Number(s.id))?.some(e2 => e1.taskId === e2.id)
            );

            const completed = filtered?.map(e => e.worked);
            const sumCompleted = completed?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0);

            const hrs = Math.floor(sumCompleted / 3600);
            let mins = Math.floor((sumCompleted - (hrs * 3600)) / 60);
            let secs = sumCompleted - (hrs * 3600) - (mins * 60);

            if (mins > 0 && mins < 10) mins = `0${mins}`;

            completedWork = `${hrs}h${mins <= 0 ? '' : mins}`;
          }

          return (
            <Column
              key={s.id}
              onDrop={(e) => handleDrop(e, s.id)}
              onDragOver={handleDragOver}
              onDragEnter={(e) => e.preventDefault()}
            >
              <div className='card-header'>
                <span>
                  <span className='badge' style={{ backgroundColor: s.color }}></span>
                  {s.description}
                </span>
                <span>
                  <Tooltip title="Horas trabalhadas">{`${completedWork}`}</Tooltip> / <Tooltip title="Esforço estimado">{`${remainingWork}`}</Tooltip>
                </span>
              </div>

              <PerfectScroll>
                <div className='card-container' ref={gridRef.current[s.id]} key={s.id}>
                  {sprint?.Tasks?.filter(e => Number(e.state) === Number(s.id))?.map((e, i) => (
                    <Task
                      key={e.id}
                      e={e}
                      step={s.id}
                      projectId={projectId}
                      sprintId={sprintId}
                      id={taskId}
                      setTaskId={setTaskId}
                      levelAdm={levelAdm}
                      otherSprints={otherSprints}
                      users={users}
                      playlist={playlist}
                      inPlay={Number(e.playing) === 1}
                      setPlaylist={setPlaylist}
                      isVisibleTaskUpdate={isVisibleTaskUpdate}
                      setIsVisibleTaskUpdate={setIsVisibleTaskUpdate}
                      totalComments={e.totalComments}
                      setTabId={setTabId}
                      position={position}
                      setPosition={setPosition}
                    />
                  ))}
                </div>
              </PerfectScroll>
            </Column>
          );
        })}
      </PerfectScroll>
    </Container>
  );
}