import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Space, Tooltip, Button } from 'antd';
import moment from 'moment';

import ModalCreate from './Create';
import ModalUpdate from './Update';

import { handleTaskCounter } from '~/pages/Task/Card/functions';

import { taskCompletedHourIndexRequest, taskCompletedHourReadRequest, taskCompletedHourDeleteRequest } from '~/store/modules/taskCompletedHour/actions';

import { PlusCircleOutlined, HourglassFilled, EditFilled, DeleteFilled } from '@ant-design/icons';
import { Container, DataList } from './styles';

export default function TaskWork({ projectId, taskId, users }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const loading = useSelector(store => store.taskCompletedHour.loading);
  const dataSource = useSelector(store => store.taskCompletedHour.list);

  const [id, setId] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [start, setStart] = useState(true);

  function onTimeUpdate(id, start = true) {
    setStart(start);
    setId(id);
    dispatch(taskCompletedHourReadRequest(id, taskId, projectId));

    setIsVisibleUpdate(true);
  }

  function onTimeDelete(id) {
    if (window.confirm('Deseja realmente excluir este registro de tempo?')) {
      dispatch(taskCompletedHourDeleteRequest(id, taskId, projectId));
    }
  }

  useMemo(() => {
    dispatch(taskCompletedHourIndexRequest(taskId, projectId));
  }, [taskId]);

  return (
    <Container>
      <div className='w-100 text-center'>
        <DataList dataSource={dataSource} loading={loading} pagination={false} footer={() => (
          <Space className='data-list-footer'>
            {handleTaskCounter(dataSource?.reduce((accumulator, object) => {
              return accumulator + parseInt(object.completedWork);
            }, 0))}
          </Space>
        )}>
          <DataList.Column dataIndex="id" key="id" render={(_, record) => {
            const user = users?.find(u => u.id === record.userId);

            return (
              <Tooltip title={user?.name}>
                <Space>
                  <Avatar size="18" style={{ backgroundColor: user?.color, verticalAlign: 'middle' }}>
                    {user?.short}
                  </Avatar>
                </Space>
              </Tooltip>
            );
          }} />

          <DataList.Column title="Início" dataIndex="startAt" key="startAt" render={(_, record) => (
            <Space
              className={`${record.startAt !== record.createdAt ? 'manually' : ''} ${(record.allowed === true || Number(record.allowed) === 1) ? 'editable' : ''}`}
              onClick={() => (record.allowed === true || Number(record.allowed) === 1) && onTimeUpdate(record.id, true)}
            >
              {moment(record.startAt).format('DD/MM/YYYY HH:mm')}
            </Space>
          )} />

          <DataList.Column title="Término" dataIndex="endAt" key="endAt" render={(_, record) => (
            <Space
              className={`${(record.endAt !== record.updatedAt && record.endAt) ? 'manually' : ''} ${(record.allowed === true || Number(record.allowed) === 1) ? 'editable' : ''}`}
              onClick={() => ((record.allowed === true || Number(record.allowed) === 1) && record.endAt) && onTimeUpdate(record.id, false)}
            >
              {record.endAt ? moment(record.endAt).format('DD/MM/YYYY HH:mm') : <Tooltip title="Tempo sendo contabilizado">
                <HourglassFilled />
              </Tooltip>}
            </Space>
          )} />

          <DataList.Column title="Tempo" dataIndex="completedWork" key="completedWork" render={(_, record) => (
            <Space>
              {handleTaskCounter(record.completedWork)}
            </Space>
          )} />

          <DataList.Column title={
            <Tooltip title="Adicionar tempo manualmente">
              <Button htmlType='button' type='ghost' icon={<PlusCircleOutlined />} onClick={() => setIsVisibleCreate(true)} />
            </Tooltip>
          } dataIndex="allowed" key="allowed" render={(_, record) => (
            <Space align='right'>
              {(record.allowed === true || Number(record.allowed) === 1) && (
                <Tooltip title="Excluir tempo registrado">
                  <DeleteFilled className='danger' onClick={() => onTimeDelete(record.id)} />
                </Tooltip>
              )}
            </Space>
          )} />
        </DataList>
      </div>

      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} projectId={projectId} taskId={taskId} />
      <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} projectId={projectId} taskId={taskId} id={id} start={start} />

    </Container>
  );
}
