export const handlePlaylist = (taskHours, setTimecount) => {
  const result = taskHours?.map(e => {
    if (Number(e.playing) === 1) setTimecount(Number(e.completedWork));

    return {
      id: e.id,
      taskId: e.taskId,
      active: Number(e.playing) === 1,
      worked: Number(e.completedWork),
      effort: Number(!e.remainingWork ? e.completedWork : e.remainingWork)
    };
  });

  return result;
};

export const handleTaskCounter = (timecount, format = '00:00:00') => {
  const hrs = Math.floor(Number(timecount) / 3600);
  let mins = Math.floor((Number(timecount) - (hrs * 3600)) / 60);
  let secs = Number(timecount) - (hrs * 3600) - (mins * 60);

  if (mins < 10) mins = `0${mins}`;
  if (secs < 10) secs = `0${secs}`;

  switch (format) {
    case '00:00:00':
      return `${hrs}:${mins}:${secs}`;

    case '00h00':
      return `${hrs}h${mins}`;
  }
};
