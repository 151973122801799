import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, DatePicker, Row, Col, Skeleton } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { taskCompletedHourUpdateRequest } from '~/store/modules/taskCompletedHour/actions';

import { Container } from './styles';

export default function Update({ id, projectId, taskId, isVisible, setIsVisible, start }) {
  const dispatch = useDispatch();

  const saving = useSelector(store => store.taskCompletedHour.saving);
  const error = useSelector(store => store.taskCompletedHour.error);
  const registry = useSelector(store => store.taskCompletedHour.registry);

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  function onFinish(values) {

    values.id = id;
    values.projectId = projectId;
    values.taskId = taskId;

    if (window.confirm('Deseja realmente alterar este registro de horas para uma entrada manual?')) {
      setIsSaving(true);
      dispatch(taskCompletedHourUpdateRequest(values));
    }
  }

  useMemo(() => {
    setLoading(true);
  }, [id]);

  useMemo(() => {
    setLoading(true);

    if (Number(registry?.id) > 0) {
      if (start) {
        form.setFieldsValue({
          startAt: moment(registry.startAt),
        });
      }
      else {
        form.setFieldsValue({
          endAt: moment(registry.endAt),
        });
      }

      setLoading(false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);
      if (!error) setIsVisible(false);
    }
  }, [saving]);

  return (
    <Modal
      title={`Editar tempo de ${start ? 'início' : 'término'}`}
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={300}
      processing={isSaving}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item
                label={start ? "Início" : "Término"}
                name={start ? "startAt" : "endAt"}
                rules={[{ required: true, message: `${start ? 'Início' : 'Término'} é obrigatório` }]}
              >
                {loading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <DatePicker showTime format="DD/MM/YYYY HH:mm" />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}
