export function taskCompletedHourIndexRequest(id, projectId) {
  return {
    type: '@taskCompletedHours/INDEX_REQUEST',
    payload: { id, projectId },
  };
}

export function taskCompletedHourIndexSuccess(data) {
  return {
    type: '@taskCompletedHours/INDEX_SUCCESS',
    payload: { data },
  };
}

export function taskCompletedHourIndexFailure() {
  return {
    type: '@taskCompletedHours/INDEX_FAILURE',
  };
}

export function taskCompletedHourCreateRequest(data) {
  return {
    type: '@taskCompletedHours/CREATE_REQUEST',
    payload: { data },
  };
}

export function taskCompletedHourCreateSuccess(data) {
  return {
    type: '@taskCompletedHours/CREATE_SUCCESS',
    payload: { data },
  };
}

export function taskCompletedHourCreateFailure() {
  return {
    type: '@taskCompletedHours/CREATE_FAILURE',
  };
}

export function taskCompletedHourReadRequest(id, taskId, projectId) {
  return {
    type: '@taskCompletedHours/READ_REQUEST',
    payload: { id, projectId, taskId },
  };
}

export function taskCompletedHourReadSuccess(data) {
  return {
    type: '@taskCompletedHours/READ_SUCCESS',
    payload: { data },
  };
}

export function taskCompletedHourReadFailure() {
  return {
    type: '@taskCompletedHours/READ_FAILURE',
  };
}

export function taskCompletedHourUpdateRequest(data) {
  return {
    type: '@taskCompletedHours/UPDATE_REQUEST',
    payload: { data },
  };
}

export function taskCompletedHourUpdateSuccess(data) {
  return {
    type: '@taskCompletedHours/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function taskCompletedHourUpdateFailure() {
  return {
    type: '@taskCompletedHours/UPDATE_FAILURE',
  };
}

export function taskCompletedHourDeleteRequest(id, taskId, projectId) {
  return {
    type: '@taskCompletedHours/DELETE_REQUEST',
    payload: { id, taskId, projectId },
  };
}

export function taskCompletedHourDeleteSuccess() {
  return {
    type: '@taskCompletedHours/DELETE_SUCCESS',
  };
}

export function taskCompletedHourDeleteFailure() {
  return {
    type: '@taskCompletedHours/DELETE_FAILURE',
  };
}
