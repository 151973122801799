import styled from 'styled-components';
import { Col } from 'antd';
import { Input, Button } from '~/pages/_layouts/default/styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const Empty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
`;

export const FilterContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const FilterInput = styled(Input)`

`;

export const FilterButton = styled(Button)`
  margin-left: 10px;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }  
`;

export const ProjectItem = styled(Col)`
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const ProjectItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 4px;
  height: 180px;
  flex-basis: 300px;
  margin-right: 32px;
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  
  @media only screen and (max-width: 992px) {
    height: 130px;
  }
`;

export const ProjectItemTitleContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: start;

  svg {
    display: none;
    margin-left: 7px;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
  }

  &:hover {
    svg {
      display: block;
    }
  }
`;

export const ProjectItemTitle = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  max-width: calc(100% - 50px);
  color: var(--project-item-title);
`;

export const ProjectItemDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const ProjectItemIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  svg {
    cursor: pointer;
    margin-left: 10px;
    font-size: 22px;
    color: var(--project-icon);

    @media only screen and (max-width: 992px) {
      font-size: 18px;
    }
  }

  .icon-project {
    svg {
      &:hover {
        color: var(--project-icon-project);
      }
    }
  }

  .icon-backlog {
    svg {
      &:hover {
        color: var(--project-icon-backlog);
      }
    }
  }

  .icon-feature {
    svg {
      &:hover {
        color: var(--project-icon-feature);
      }
    }
  }

  .icon-epic {
    svg {
      &:hover {
        color: var(--project-icon-epic);
      }
    }
  }

  .icon-sprint {
    svg {
      &:hover {
        color: var(--project-icon-sprint);
      }
    }
  }

  .icon-repository {
    svg {
      &:hover {
        color: var(--project-icon-repository);
      }
    }
  }
`;
