export function mailTermsInsertSuccess(data) {
  return {
    type: '@mailTerms/INSERT_SUCCESS',
    payload: { data },
  };
}

export function mailTermsCreateSuccess(data) {
  return {
    type: '@mailTerms/CREATE_SUCCESS',
    payload: { data },
  };
}

export function mailTermsUpdateSuccess(data) {
  return {
    type: '@mailTerms/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function mailTermsDeleteSuccess(key) {
  return {
    type: '@mailTerms/DELETE_SUCCESS',
    payload: { key },
  };
}

export function mailTermsCleanSuccess() {
  return {
    type: '@mailTerms/CLEAN_SUCCESS',
  };
}
