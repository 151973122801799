import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, DatePicker, Row, Col } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { taskCompletedHourCreateRequest } from '~/store/modules/taskCompletedHour/actions';

import { Container } from './styles';

export default function Create({ projectId, taskId, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.taskCompletedHour.loading);
  const error = useSelector(store => store.taskCompletedHour.error);
  const saving = useSelector(store => store.taskCompletedHour.saving);

  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onReset() {
    form.setFieldsValue({
      startAt: moment(),
      endAt: moment(),
    });
  }

  function onFinish(values) {
    setIsSaving(true);

    values.projectId = projectId;
    values.taskId = taskId;

    dispatch(taskCompletedHourCreateRequest(values));
  }

  useMemo(() => {
    if (isSaving && !saving) {
      setIsSaving(false);

      if (!error) {
        setIsVisible(false);
        onReset()
      }
    }
  }, [saving]);

  return (
    <Modal
      title="Adicionar tempo"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={500}
      processing={isSaving}
    >
      <Container>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            startAt: moment(),
            endAt: moment()
          }}
          onFinish={onFinish}>
          <Row gutter={8}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Início"
                name="startAt"
                rules={[{ required: true, message: 'Início é obrigatório' }]}
              >
                <DatePicker showTime format="DD-MM-YYYY HH:mm" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Término"
                name="endAt"
                rules={[{ required: true, message: 'Término é obrigatório' }]}
              >
                <DatePicker showTime format="DD-MM-YYYY HH:mm" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}
