export function taskIndexRequest(filters) {
  return {
    type: '@tasks/INDEX_REQUEST',
    payload: { filters },
  };
}

export function taskIndexSuccess(data) {
  return {
    type: '@tasks/INDEX_SUCCESS',
    payload: { data },
  };
}

export function taskIndexFailure() {
  return {
    type: '@tasks/INDEX_FAILURE',
  };
}

export function taskCreateRequest(data) {
  return {
    type: '@tasks/CREATE_REQUEST',
    payload: { data },
  };
}

export function taskCreateSuccess(data) {
  return {
    type: '@tasks/CREATE_SUCCESS',
    payload: { data },
  };
}

export function taskCreateFailure() {
  return {
    type: '@tasks/CREATE_FAILURE',
  };
}

export function taskReadRequest(data) {
  return {
    type: '@tasks/READ_REQUEST',
    payload: { data },
  };
}

export function taskReadSuccess(data) {
  return {
    type: '@tasks/READ_SUCCESS',
    payload: { data },
  };
}

export function taskReadFailure() {
  return {
    type: '@tasks/READ_FAILURE',
  };
}

export function taskUpdateRequest(data) {
  return {
    type: '@tasks/UPDATE_REQUEST',
    payload: { data },
  };
}

export function taskUpdateSprintRequest(data) {
  return {
    type: '@tasks/UPDATE_SPRINT_REQUEST',
    payload: { data },
  };
}

export function taskUpdateSuccess(data) {
  return {
    type: '@tasks/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function taskUpdateFailure() {
  return {
    type: '@tasks/UPDATE_FAILURE',
  };
}

export function taskDeleteRequest(id) {
  return {
    type: '@tasks/DELETE_REQUEST',
    payload: { id },
  };
}

export function taskDeleteSuccess() {
  return {
    type: '@tasks/DELETE_SUCCESS',
  };
}

export function taskDeleteFailure() {
  return {
    type: '@tasks/DELETE_FAILURE',
  };
}
