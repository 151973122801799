import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import PerfectScroll from 'react-perfect-scrollbar';

import history from '~/services/history';

import MenuContent from '~/components/Menu';
import HeaderContent from '~/components/Header';
import TaskPlay from '~/components/TaskPlay';

import { Container, Content, Wrapper, Layout, Header, Body, Sider, Footer, Logo } from './styles';

export default function DefaultLayout({ children }) {
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);

  function onCollapse(collapsed) {
    setCollapsed(collapsed);
  }

  return (
    <Container>
      <PerfectScroll style={{ height: '100%', position: 'fixed' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} className={isMobile ? 'isMobile' : ''}>
          <div className="logo">
            <Logo onClick={() => history.push('/')} className={`${collapsed && 'is-collapsed'}`}>
              <div>
                <span>StationUp</span>
                <span>DevOps</span>
              </div>
            </Logo>
          </div>
          <MenuContent />
        </Sider>
      </PerfectScroll>

      <Layout style={{ marginLeft: collapsed ? '80px' : '200px', transition: `all ${collapsed ? '0.7' : '0.2'}s` }}>
        <Header>
          <HeaderContent />
        </Header>

        <Body>
          <Wrapper>
            <Content className={`main ${isMobile ? 'is-mobile' : ''}`}>
              {children}
            </Content>
          </Wrapper>
        </Body>
      </Layout>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
