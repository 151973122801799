import React from 'react';
import PropTypes from 'prop-types';

import { Table } from './styles';

export default function DataList({ children, ...rest }) {
  return (
    <Table {...rest}>
      {children}
    </Table>
  );
}

DataList.propTypes = {
  children: PropTypes.node.isRequired,
};
