export function taskHourIndexRequest(id, filters) {
  return {
    type: '@taskHours/INDEX_REQUEST',
    payload: { id, filters },
  };
}

export function taskHourIndexSuccess(data) {
  return {
    type: '@taskHours/INDEX_SUCCESS',
    payload: { data },
  };
}

export function taskHourIndexFailure() {
  return {
    type: '@taskHours/INDEX_FAILURE',
  };
}

export function taskHourCreateRequest(data) {
  return {
    type: '@taskHours/CREATE_REQUEST',
    payload: { data },
  };
}

export function taskHourCreateSuccess(data) {
  return {
    type: '@taskHours/CREATE_SUCCESS',
    payload: { data },
  };
}

export function taskHourCreateFailure() {
  return {
    type: '@taskHours/CREATE_FAILURE',
  };
}

export function taskHourReadRequest(data) {
  return {
    type: '@taskHours/READ_REQUEST',
    payload: { data },
  };
}

export function taskHourReadSuccess(data) {
  return {
    type: '@taskHours/READ_SUCCESS',
    payload: { data },
  };
}

export function taskHourReadFailure() {
  return {
    type: '@taskHours/READ_FAILURE',
  };
}

export function taskHourUpdateRequest(data) {
  return {
    type: '@taskHours/UPDATE_REQUEST',
    payload: { data },
  };
}

export function taskHourUpdateSuccess(data) {
  return {
    type: '@taskHours/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function taskHourUpdateFailure() {
  return {
    type: '@taskHours/UPDATE_FAILURE',
  };
}

export function taskHourDeleteRequest(id) {
  return {
    type: '@taskHours/DELETE_REQUEST',
    payload: { id },
  };
}

export function taskHourDeleteSuccess() {
  return {
    type: '@taskHours/DELETE_SUCCESS',
  };
}

export function taskHourDeleteFailure() {
  return {
    type: '@taskHours/DELETE_FAILURE',
  };
}

export function taskHourTimecount(data) {
  return {
    type: '@taskHours/TIMECOUNT',
    payload: { data },
  };
}
