import styled from 'styled-components';
import { Menu } from 'antd';

export const Container = styled(Menu)`
  background: var(--header-background) !important;
  padding-bottom: 3rem;
`;

export const Item = styled(Menu.Item)`
  font-size: 0.8rem;

  &.menu-group {
    cursor: default;
    color: var(--project-icon) !important;
    background-color: transparent !important;

    &:hover, &:focus, &:active {
      color: var(--project-icon) !important;
      background-color: transparent !important;
    }
  }
`;

export const ProjectBadge = styled.span`
  display: inline-block;
  border-radius: 50%;
  width: 0.9rem;
  height: 0.9rem;
`;
